(function(factory) {
	
	if (typeof define === 'function' && define.amd) {
        define(['jquery', 'lodash', 'jquery-ui/data', 'jquery-ui/disable-selection', 'jquery-ui/focusable',
            'jquery-ui/form', 'jquery-ui/ie', 'jquery-ui/keycode', 'jquery-ui/labels', 'jquery-ui/jquery-1-7',
            'jquery-ui/plugin', 'jquery-ui/safe-active-element', 'jquery-ui/safe-blur', 'jquery-ui/scroll-parent',
            'jquery-ui/tabbable', 'jquery-ui/unique-id', 'jquery-ui/version', 'jquery-ui/widget',
            'jquery-ui/widgets/mouse', 'jquery-ui/widgets/draggable', 'jquery-ui/widgets/droppable',
            'jquery-ui/widgets/resizable','d3'], factory);
    } else if (typeof exports !== 'undefined') {
        try { jQuery = require('jquery'); } catch (e) {}
        try { _ = require('lodash'); } catch (e) {}
        factory(jQuery);//, _);
    } else {
        factory(jQuery);//, _);
    }

    if (d3.getTransformation === undefined){
    	d3.getTransformation = function (transform) {
			// Create a dummy g for calculation purposes only. This will never
			// be appended to the DOM and will be discarded once this function 
			// returns.
			var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

			// Set the transform attribute to the provided string value.
			g.setAttributeNS(null, "transform", transform);

			// consolidate the SVGTransformList containing all transformations
			// to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
			// its SVGMatrix. 
			var matrix = g.transform.baseVal.consolidate().matrix;

			// Below calculations are taken and adapted from the private function
			// transform/decompose.js of D3's module d3-interpolate.
			var {a, b, c, d, e, f} = matrix;   // ES6, if this doesn't work, use below assignment
			// var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
			var scaleX, scaleY, skewX;
			if (scaleX = Math.sqrt(a * a + b * b)) a /= scaleX, b /= scaleX;
			if (skewX = a * c + b * d) c -= a * skewX, d -= b * skewX;
			if (scaleY = Math.sqrt(c * c + d * d)) c /= scaleY, d /= scaleY, skewX /= scaleY;
			if (a * d < b * c) a = -a, b = -b, skewX = -skewX, scaleX = -scaleX;
			return {
			translateX: e,
			translateY: f,
			rotate: Math.atan2(b, a) * 180 / Math.PI,
			skewX: Math.atan(skewX) * 180 / Math.PI,
			scaleX: scaleX,
			scaleY: scaleY
			};
		};
    }
    
    if (d3.clickCancel === undefined){

	    d3.clickCancel = function() {
		    // we want to a distinguish single/double click
			// details http://bl.ocks.org/couchand/6394506
			var dispatcher = d3.dispatch('click', 'dblclick');
			function cc(selection) {
				var down, tolerance = 5, last, wait = null, args;
			// euclidean distance
			function dist(a, b) {
				return Math.sqrt(Math.pow(a[0] - b[0], 2), Math.pow(a[1] - b[1], 2));
			}
			selection.on('mousedown', function() {
				down = d3.mouse(document.body);
				last = +new Date();
				args = arguments;
			});
			selection.on('mouseup', function() {
				if (dist(down, d3.mouse(document.body)) > tolerance) {
					return;
				} else {
					if (wait) {
						window.clearTimeout(wait);
						wait = null;
						dispatcher.apply("dblclick", this, args);
					} else {
						wait = window.setTimeout((function() {
							return function() {
								dispatcher.apply("click", this, args);
								wait = null;
							};
						})(), 300);
					}
				}
			});
		};
			// Copies a variable number of methods from source to target.
			var d3rebind = function(target, source) {
				var i = 1, n = arguments.length, method;
				while (++i < n) target[method = arguments[i]] = d3_rebind(target, source, source[method]);
				return target;
			};

			// Method is assumed to be a standard D3 getter-setter:
			// If passed with no arguments, gets the value.
			// If passed with arguments, sets the value and returns the target.
			function d3_rebind(target, source, method) {
				return function() {
					var value = method.apply(source, arguments);
					return value === source ? target : value;
				};
			}
			return d3rebind(cc, dispatcher, 'on');
		};
	}

	


})(function($){ //, _) {

	var scope = window;

	var MathFlow = function(el, opts) {

		// Container
		this.container = $(el);

		// Main stuff
		this.name             = "";
		this.items            = [];
		this.links            = [];
		this.svg_container_id = 'svg_container_' + this.generateUUID();
		this.svg_id           = this.generateUUID();
		this.locale           = "en_US";

		// Common definitions:
		this.defs = {
			items : {
				types : {
					begin    : "begin",
					process  : "process",
					decision : "decision",
					end      : "end"
				},
				resizehandle : {
					width    : 6,
					height   : 6
				},
				dimensions   : {
					min_width  : 40,
					min_height : 40,
					max_width  : 800,
					max_height : 800,

				},
				inputs : {
					types : {
						double    : "double",
						integer   : "integer",
						timestamp : "timestamp" 
					}
				},
				outputs : {
					types : {
						double    : "double",
						integer   : "integer",
						timestamp : "timestamp" 
					}
				}
			},
			connectors : {
				curved_line_interpolation   : d3.curveLinear,//d3.curveCardinal,
				straight_line_interpolation : d3.curveLinear,
			},
			events : {
				types : {
					showItemInfo   : "showItemInfo",
					diagramChanged : "diagramChanged" 
				}
			}
		};

		this.language = {
			items 			  : {
				default_names : {
					begin     : "Start",
					end       : "End",
					process   : "Process",
					decision  : "Decision"
				},
				edit            : "Edit step information",
				close           : "Close",
				remove          : "Remove step",
				enter_name      : "Name",
				enter_formula   : "Apply formula",
				preview_formula : "Preview",
				enter_condition : "Condition clause",
				inputs          : {
					types     : {
						double    : "Double",
						integer   : "Integer",
						timestamp : "Timestamp" 
					},
					add_input      : "Add input",
					add_input_icon : "mdi mdi-plus",
					remove_input      : "Remove input",
					remove_input_icon : "mdi mdi-delete",
					input     : "Input",
					type 	  : "Type",
					actions   : "Action",
					operator  : "Operator",
					compare_value : "Value", 

				},
				outputs         : {
					types     : {
						double        : "Double",
						integer       : "Integer",
						timestamp     : "Timestamp" 
					},
					decision_yes      : "TRUE",
					decision_no       : "FALSE",
				}
			}
			
		};

		this.language_es_ES = {
			items 			  : {
				default_names : {
					begin     : "Inicio",
					end       : "Fin",
					process   : "Procesado",
					decision  : "Decisión"
				},
				edit          : "Editar etapa",
				close         : "Cerrar",
				remove          : "Eliminar etapa",
				enter_name      : "Nombre",
				enter_formula   : "Aplicar fórmula",
				preview_formula : "Previsualización",
				enter_condition : "Condición",
				inputs          : {
					types     : {
						double    : "Doble",
						integer   : "Entero",
						timestamp : "Marca temporal" 
					},
					add_input      : "Añadir entrada",
					add_input_icon : "mdi mdi-plus",
					remove_input      : "Eliminar entrada",
					remove_input_icon : "mdi mdi-delete",
					input     : "Entrada",
					type 	  : "Tipo",
					actions   : "Acción",
					operator  : "Operador",
					compare_value : "Valor", 

				},
				outputs         : {
					types     : {
						double        : "Doble",
						integer       : "Entero",
						timestamp     : "Marca temporal" 
					},
					decision_yes      : "VERDADERO",
					decision_no       : "FALSO",
				}
			}
			
		};

		var  deep_copy = function(src) {
			var target = {};
			for (var prop in src) {
				if (src.hasOwnProperty(prop)) {
					if (Array.isArray(src[prop])){
						target[prop] = [];
						for (let i = 0; i< src[prop].length; i++){
							target[prop].push(deep_copy(src[prop][i]));
						}
					}else if (src[prop] === null ){
						target[prop] = null;
					}else if (typeof src[prop] === "object" ){
						target[prop] = deep_copy(src[prop]);	
					}else{
						target[prop] = src[prop];	
					}
				}
			}
			return target;
		};

		// Update main stuff with options:
		for (var key in opts){
			if ((key === "items") || (key === "links")){
				continue;
			}

			if (typeof opts[key] === "object" ){
				this[key] = deep_copy(opts[key]);	
			}else{
				this[key] = opts[key];
			}

			if ((key === "locale") && (opts[key] === "es_ES") ){
				this.language = this.language_es_ES
			}
		}
		

		var self = this;
		this.container.addClass("mathflow");
		this.container.append('<div class="mathflow-item-info"></div><div id="' + this.svg_container_id + '" class="mathflow-draw-area"></div>');
		

		// Append the svg object to the div
        this.svg   = d3.select('#' + this.svg_container_id)
            .append("svg")
                .attr("id",  this.svg_id)
                .attr("class", "mathflow-svg")
                .attr("viewBox", "0 0 1000 1000")
                .attr("xmlns", "http://www.w3.org/2000/svg");

        // Defs:
        this.svg_defs = this.svg.append("defs");

        // Marker def:
        this.marker_arrow_id = "arrow_" + this.generateUUID();

        this.svg_defs.append("marker")
        	.attr("id", this.marker_arrow_id)
			.attr("markerUnits","strokeWidth")
			.attr("markerWidth","12")
			.attr("markerHeight","12")
			.attr("viewBox","0 0 12 12")
			.attr("refX","6")
			.attr("refY","6")
			.attr("orient","auto")
      		.append("path")
      			// .attr("d","M2,2 L10,6 L2,10 L6,6 L2,2")
      			.attr("d","M1,4 L5,6 L1,8 L2,6 L1,4")
      			.attr("class", "mathflow-marker-arrow");
      		
		this.svg.append("rect")
			.attr("class", "mathflow-grid")
            .attr("x","0")
            .attr("y","0")
            .attr("width" ,"100%")
            .attr("height","100%")
            	.on("click",function(){
            		
            		self.container.find(".mathflow-item-info").html('');
            		$(this.container).find('.mathflow-item-info').removeClass("active");
            		d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
            	});
		
		let grid_lines = this.svg.append("g")
			.attr("class", "mathflow-grid-lines");

		let i;
		for (i=1; i<20; i++){
			grid_lines.append("line")
				.attr("class", "mathflow-grid-line")
				.attr("x1", (5*i) + "%")
				.attr("y1", "0%")
				.attr("x2", (5*i) + "%")
				.attr("y2", "100%");

			grid_lines.append("line")
				.attr("class", "mathflow-grid-line")
				.attr("y1", (5*i) + "%")
				.attr("x1", "0%")
				.attr("y2", (5*i) + "%")
				.attr("x2", "100%");
		}
		

		// Path group
		this.svg.append("g")
			.attr("class", "mathflow-paths");


		
		// Add items, if defined with opts:
		if (opts.items !== undefined){
			for (i=0; i<opts.items.length; i++){
				this.addItem(opts.items[i]);
			}	
		}
		

		// Add links, if defined with opts:
		if (opts.links !== undefined){
			for (i=0; i<opts.links.length; i++){
				this.addConnector(opts.links[i].output_id, opts.links[i].input_id);
			}
		}


	};

	

	MathFlow.prototype.width =  function() {

		let dimensions = this.svg.node().getBBox();
		return dimensions.width;

	};

	MathFlow.prototype.height =  function() {

		let dimensions = this.svg.node().getBBox();
		return dimensions.height;

	};


	

    MathFlow.prototype.generateUUID = function() {
        // jshint unused:true
        let max = 5;
        let seed_type = "lower_case";
        
        var text = "";
        var possible = "";

        // var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        switch (seed_type) {
            case "upper_case":
                possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            break;

            case "lower_case":
                possible = "abcdefghijklmnopqrstuvwxyz";
            break;

            case "numeric":
                possible = "0123456789";
            break;

            case "full":
                possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            break;

            default:
                possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            break;
        }

        for( var i=0; i < max; i++ ) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };

    
	MathFlow.prototype.addItem = function(options){

		var item_type = options.type;

		if ((options.is_editable === "0") || (options.is_editable === "false") || (options.is_editable === 0) || (options.is_editable === false)){
			options.is_editable = false;
		}else{
			options.is_editable = true;
		}

		switch(item_type){

			
			case this.defs.items.types.begin:
				return this.addItemBegin(options);

			case this.defs.items.types.end:
				return this.addItemEnd(options);

			case this.defs.items.types.process:
				return this.addItemProcess(options);

			case this.defs.items.types.decision:
				return this.addItemDecision(options);

			default:
			break;
		}

		return this;

		
	};


	MathFlow.prototype.addItemBegin = function(options){

		var self = this;	
		
		var item_opts = {
			id      : (options.id      === undefined) ? "item_" + this.generateUUID() : options.id,
			name    : (options.name    === undefined) ? this.language.items.default_names.begin : options.name,
			x       : options.x, 
			y       : options.y,
			width   : (options.width    === undefined) ? 200 : options.width,
			height  : (options.height   === undefined) ? 100 : options.height,
			type    : options.type,
			inputs  : [],
			outputs : [],
			is_editable : (options.height   === undefined) ? false : options.is_editable,
			
		};



		this.items.push(item_opts);

		
		var oldMousePosX = null;
		var oldMousePosY = null;
		var itemDragStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var itemDragEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var itemDragged = function() {
		

			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
			
			
			let currentX = aux_g_transform.translateX;//dimensions.x;//d3.select(this).attr("x");
			let currentY = aux_g_transform.translateY;//dimensions.y;//d3.select(this).attr("y");
		

			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){
				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}



			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;

			let newX = Math.max(0, Math.min(self.width() - item_opts.width, (incX + currentX) ) );
			let newY = Math.max(0, Math.min(self.height() - item_opts.height, (incY + currentY) ) );

			aux_g.attr("transform", "translate(" + newX + "," + newY + ")" );
			
				
			for (let i=0; i < self.links.length; i++){

				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

			self.updateItem(item_id, {
				x : newX, 
				y : newY
			}); 

		
		};


		

	
		var item_g = this.svg.append("g")
			.attr("id", item_opts.id)
			.attr("class", "mathflow-item-group " + item_opts.type)
			.attr("transform", "translate(" + item_opts.x + "," + item_opts.y + ")" );

		var item = item_g.append("rect")
			.attr("id", "rect-" + item_opts.id)
			.attr("x", 0)
			.attr("y", 0)
			.attr("class", "mathflow-item")
			.attr("width", item_opts.width)
			.attr("height", item_opts.height)
			.attr("rx", (item_opts.height/2))
			.attr("ry", (item_opts.height/2));

		if (item_opts.is_editable){
			item.attr("cursor", "move")
				.call(d3.drag()
					.on("start", itemDragStart)
					.on("drag", itemDragged)
					.on("end", itemDragEnd)
				)
				.call(d3.clickCancel);

			item.on("dblclick",function(){
				// d3.select('#' + self.svg_id).selectAll(".mathflow-item").classed("selected-item", false);
				// d3.select('#' + self.svg_id).selectAll(".mathflow-item-resize-handle").classed("selected-item", false);
				// d3.select(this).classed("selected-item", true);
				// d3.select(this.parentNode).select(".mathflow-item-resize-handle").classed("selected-item", true);
				// self.showItemInfo(item_opts.id);
				d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
				d3.select(this.parentNode).classed("selected-item", true);
				self.showItemInfo(item_opts.id);
			});
		}	
			
		// Add the item name:
		var itemName = item_g.append("g")
			.attr("id", "name-" + item_opts.id)
			.attr("transform", "translate(" + (item_opts.width/2) + "," + (item_opts.height/2) + ")")
			.attr("class", "mathflow-item-name")
				.append("text")
					.attr("id",item_opts.id + "_name")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(item_opts.name);


		// Add the resize handle:
		var resizeStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var resizeEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var resizing = function() {
		
			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
						
			let currentX = aux_g_transform.translateX;
			let currentY = aux_g_transform.translateY;

			let aux_item         = aux_g.select("rect.mathflow-item");
			let aux_item_text_g  = aux_g.select(".mathflow-item-name");
			let aux_resizeHandle = aux_g.select("rect.mathflow-item-resize-handle");

			let currentWidth  = Number(aux_item.attr("width"));
			let currentHeight = Number(aux_item.attr("height"));


			
			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){

				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}


			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;


			

			let newWidth  = Math.min(self.defs.items.dimensions.max_width, Math.max(self.defs.items.dimensions.min_width, currentWidth + incX));
			let newHeight = Math.min(self.defs.items.dimensions.max_height, Math.max(self.defs.items.dimensions.min_height, currentHeight + incY));

			

			if (currentX + newWidth > self.width() ){
				newWidth = self.width() - currentX;
			}

			if (currentY + newHeight > self.height() ){
				newHeight = self.height() - currentY;
			}

			aux_item.attr("width", newWidth);
			aux_item.attr("height", newHeight);
			aux_item_text_g.attr("transform", "translate(" + (newWidth/2) + "," + (newHeight/2) + ")");
			aux_resizeHandle.attr("x", newWidth);
			aux_resizeHandle.attr("y", newHeight);


			// Update input positions:
			let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;
			let input_diff = newWidth/(total_inputs+1);
			for (let i = 0; i < total_inputs; i++){
				item_g.select('#' + item_opts.inputs[i].id)
					.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
			}

			// Update output positions:
			let total_outputs = item_g.selectAll('.mathflow-output-group').nodes().length;
			let output_diff = newWidth/(total_outputs+1);
			for (let i = 0; i < total_outputs; i++){
				item_g.select('#' + item_opts.outputs[i].id)
					.attr("transform","translate(" + output_diff*(i+1) + "," + newHeight + ")" );
			}			

			self.updateItem(item_id, {
				width  : newWidth, 
				height : newHeight
			}); 

			for (let i=0; i < self.links.length; i++){
				
				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

		
		};
		if (item_opts.is_editable){
			var resizeHandle = item_g.append("rect")
				.attr("id", "resize-handle-" + item_opts.id)
				.attr("class", "mathflow-item-resize-handle")
				.attr("x", item_opts.width)
				.attr("y", item_opts.height)
				.attr("transform", "translate(-" + this.defs.items.resizehandle.width + ",-" + this.defs.items.resizehandle.height + ")")
				.attr("width", this.defs.items.resizehandle.width)
				.attr("height", this.defs.items.resizehandle.height)
				.attr("cursor", "nw-resize")
				.call(d3.drag()
					.on("start", resizeStart)
					.on("drag", resizing)
					.on("end", resizeEnd)
				);
	
		}
		

		if (options.inputs !== undefined){
			this.addInputs(item_opts.id , options.inputs);
		}

		if (options.outputs !== undefined){
			this.addOutputs(item_opts.id , options.outputs);
		}

		this.signalDiagramChangedEvent();

		return this;
	};

	MathFlow.prototype.addItemEnd = function(options){

		var self = this;
		
		var item_opts = {
			id      : (options.id      === undefined) ? "item_" + this.generateUUID() : options.id,
			name    : (options.name    === undefined) ? this.language.items.default_names.end : options.name,
			x       : options.x, 
			y       : options.y,
			width   : (options.width    === undefined) ? 200 : options.width,
			height  : (options.height   === undefined) ? 100 : options.height,
			type    : options.type,
			inputs  : [],
			outputs : [],
			is_editable : (options.height   === undefined) ? false : options.is_editable,
			
		};



		this.items.push(item_opts);

		
		var oldMousePosX = null;
		var oldMousePosY = null;
		var itemDragStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var itemDragEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var itemDragged = function() {
		

			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
			
			
			let currentX = aux_g_transform.translateX;//dimensions.x;//d3.select(this).attr("x");
			let currentY = aux_g_transform.translateY;//dimensions.y;//d3.select(this).attr("y");
		

			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){
				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}



			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;

			let newX = Math.max(0, Math.min(self.width() - item_opts.width, (incX + currentX) ) );
			let newY = Math.max(0, Math.min(self.height() - item_opts.height, (incY + currentY) ) );

			aux_g.attr("transform", "translate(" + newX + "," + newY + ")" );
			
				
			for (let i=0; i < self.links.length; i++){

				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

			self.updateItem(item_id, {
				x : newX, 
				y : newY
			}); 

		
		};


		

	
		var item_g = this.svg.append("g")
			.attr("id", item_opts.id)
			.attr("class", "mathflow-item-group " + item_opts.type)
			.attr("transform", "translate(" + item_opts.x + "," + item_opts.y + ")" );

		var item = item_g.append("rect")
			.attr("id", "rect-" + item_opts.id)
			.attr("x", 0)
			.attr("y", 0)
			.attr("class", "mathflow-item")
			.attr("width", item_opts.width)
			.attr("height", item_opts.height)
			.attr("rx", (item_opts.height/2))
			.attr("ry", (item_opts.height/2));

		if (item_opts.is_editable){
			item.attr("cursor", "move")
				.call(d3.drag()
					.on("start", itemDragStart)
					.on("drag", itemDragged)
					.on("end", itemDragEnd)
				)
				.call(d3.clickCancel);

			item.on("dblclick",function(){
				// d3.select('#' + self.svg_id).selectAll(".mathflow-item").classed("selected-item", false);
				// d3.select('#' + self.svg_id).selectAll(".mathflow-item-resize-handle").classed("selected-item", false);
				// d3.select(this).classed("selected-item", true);
				// d3.select(this.parentNode).select(".mathflow-item-resize-handle").classed("selected-item", true);
				// self.showItemInfo(item_opts.id);
				d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
				d3.select(this.parentNode).classed("selected-item", true);
				self.showItemInfo(item_opts.id);
			});
		}	
			
		// Add the item name:
		var itemName = item_g.append("g")
			.attr("id", "name-" + item_opts.id)
			.attr("transform", "translate(" + (item_opts.width/2) + "," + (item_opts.height/2) + ")")
			.attr("class", "mathflow-item-name")
				.append("text")
					.attr("id",item_opts.id + "_name")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(item_opts.name);


		// Add the resize handle:
		var resizeStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var resizeEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var resizing = function() {
		
			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
						
			let currentX = aux_g_transform.translateX;
			let currentY = aux_g_transform.translateY;

			let aux_item         = aux_g.select("rect.mathflow-item");
			let aux_item_text_g  = aux_g.select(".mathflow-item-name");
			let aux_resizeHandle = aux_g.select("rect.mathflow-item-resize-handle");

			let currentWidth  = Number(aux_item.attr("width"));
			let currentHeight = Number(aux_item.attr("height"));


			
			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){

				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}


			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;


			

			let newWidth  = Math.min(self.defs.items.dimensions.max_width, Math.max(self.defs.items.dimensions.min_width, currentWidth + incX));
			let newHeight = Math.min(self.defs.items.dimensions.max_height, Math.max(self.defs.items.dimensions.min_height, currentHeight + incY));

			

			if (currentX + newWidth > self.width() ){
				newWidth = self.width() - currentX;
			}

			if (currentY + newHeight > self.height() ){
				newHeight = self.height() - currentY;
			}

			aux_item.attr("width", newWidth);
			aux_item.attr("height", newHeight);
			aux_item_text_g.attr("transform", "translate(" + (newWidth/2) + "," + (newHeight/2) + ")");
			aux_resizeHandle.attr("x", newWidth);
			aux_resizeHandle.attr("y", newHeight);


			// Update input positions:
			let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;
			let input_diff = newWidth/(total_inputs+1);
			for (let i = 0; i < total_inputs; i++){
				item_g.select('#' + item_opts.inputs[i].id)
					.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
			}

			// Update output positions:
			let total_outputs = item_g.selectAll('.mathflow-output-group').nodes().length;
			let output_diff = newWidth/(total_outputs+1);
			for (let i = 0; i < total_outputs; i++){
				item_g.select('#' + item_opts.outputs[i].id)
					.attr("transform","translate(" + output_diff*(i+1) + "," + newHeight + ")" );
			}			

			self.updateItem(item_id, {
				width  : newWidth, 
				height : newHeight
			}); 

			for (let i=0; i < self.links.length; i++){
				
				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

		
		};
		if (item_opts.is_editable){
			var resizeHandle = item_g.append("rect")
				.attr("id", "resize-handle-" + item_opts.id)
				.attr("class", "mathflow-item-resize-handle")
				.attr("x", item_opts.width)
				.attr("y", item_opts.height)
				.attr("transform", "translate(-" + this.defs.items.resizehandle.width + ",-" + this.defs.items.resizehandle.height + ")")
				.attr("width", this.defs.items.resizehandle.width)
				.attr("height", this.defs.items.resizehandle.height)
				.attr("cursor", "nw-resize")
				.call(d3.drag()
					.on("start", resizeStart)
					.on("drag", resizing)
					.on("end", resizeEnd)
				);
	
		}
		

		if (options.inputs !== undefined){
			this.addInputs(item_opts.id , options.inputs);
		}

		if (options.outputs !== undefined){
			this.addOutputs(item_opts.id , options.outputs);
		}

		this.signalDiagramChangedEvent();

		return this;
	};


	MathFlow.prototype.addItemProcess = function(options){

	
		var self = this;
		
		var item_opts = {
			id      : (options.id      === undefined) ? "item_" + this.generateUUID() : options.id,
			name    : (options.name    === undefined) ? this.language.items.default_names.process : options.name,
			x       : options.x, 
			y       : options.y,
			width   : (options.width    === undefined) ? 200 : options.width,
			height  : (options.height   === undefined) ? 100 : options.height,
			type    : options.type,
			inputs  : [],
			outputs : [],
			formula : (options.formula  === undefined) ? "" : options.formula,

		};



		this.items.push(item_opts);

		
		var oldMousePosX = null;
		var oldMousePosY = null;
		var itemDragStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var itemDragEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var itemDragged = function() {
		

			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
			
			
			let currentX = aux_g_transform.translateX;//dimensions.x;//d3.select(this).attr("x");
			let currentY = aux_g_transform.translateY;//dimensions.y;//d3.select(this).attr("y");
		

			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){
				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}



			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;

			let newX = Math.max(0, Math.min(self.width() - item_opts.width, (incX + currentX) ) );
			let newY = Math.max(0, Math.min(self.height() - item_opts.height, (incY + currentY) ) );

			aux_g.attr("transform", "translate(" + newX + "," + newY + ")" );
			
				
			for (let i=0; i < self.links.length; i++){

				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

			self.updateItem(item_id, {
				x : newX, 
				y : newY
			}); 

		
		};


		

	
		var item_g = this.svg.append("g")
			.attr("id", item_opts.id)
			.attr("class", "mathflow-item-group " + item_opts.type)
			.attr("transform", "translate(" + item_opts.x + "," + item_opts.y + ")" );

		var item = item_g.append("rect")
			.attr("id", "rect-" + item_opts.id)
			.attr("x", 0) //item_opts.x)
			.attr("y", 0) //item_opts.y)
			.attr("class", "mathflow-item")
			.attr("width", item_opts.width)
			.attr("height", item_opts.height)
			.attr("cursor", "move")
			.call(d3.drag()
				.on("start", itemDragStart)
				.on("drag", itemDragged)
				.on("end", itemDragEnd)
			)
			.call(d3.clickCancel);

		item.on("dblclick",function(){
			// d3.select('#' + self.svg_id).selectAll(".mathflow-item").classed("selected-item", false);
			// d3.select('#' + self.svg_id).selectAll(".mathflow-item-resize-handle").classed("selected-item", false);
			// d3.select(this).classed("selected-item", true);
			// d3.select(this.parentNode).select(".mathflow-item-resize-handle").classed("selected-item", true);
			// self.showItemInfo(item_opts.id);
			d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
			d3.select(this.parentNode).classed("selected-item", true);
			self.showItemInfo(item_opts.id);
		});


		// Add the item name:
		var itemName = item_g.append("g")
			.attr("id", "name-" + item_opts.id)
			.attr("transform", "translate(" + (item_opts.width/2) + "," + (item_opts.height/2) + ")")
			.attr("class", "mathflow-item-name")
				.append("text")
					.attr("id",item_opts.id + "_name")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(item_opts.name);


		// Add the resize handle:
		var resizeStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var resizeEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var resizing = function() {
		
			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
						
			let currentX = aux_g_transform.translateX;
			let currentY = aux_g_transform.translateY;

			let aux_item         = aux_g.select("rect.mathflow-item");
			let aux_item_text_g  = aux_g.select(".mathflow-item-name");
			let aux_resizeHandle = aux_g.select("rect.mathflow-item-resize-handle");

			let currentWidth  = Number(aux_item.attr("width"));
			let currentHeight = Number(aux_item.attr("height"));


			
			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){

				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}


			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;


			

			let newWidth  = Math.min(self.defs.items.dimensions.max_width, Math.max(self.defs.items.dimensions.min_width, currentWidth + incX));
			let newHeight = Math.min(self.defs.items.dimensions.max_height, Math.max(self.defs.items.dimensions.min_height, currentHeight + incY));

			

			if (currentX + newWidth > self.width() ){
				newWidth = self.width() - currentX;
			}

			if (currentY + newHeight > self.height() ){
				newHeight = self.height() - currentY;
			}

			aux_item.attr("width", newWidth);
			aux_item.attr("height", newHeight);
			aux_item_text_g.attr("transform", "translate(" + (newWidth/2) + "," + (newHeight/2) + ")");
			aux_resizeHandle.attr("x", newWidth);
			aux_resizeHandle.attr("y", newHeight);


			// Update input positions:
			let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;
			let input_diff = newWidth/(total_inputs+1);
			for (let i = 0; i < total_inputs; i++){
				item_g.select('#' + item_opts.inputs[i].id)
					.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
			}

			// Update output positions:
			let total_outputs = item_g.selectAll('.mathflow-output-group').nodes().length;
			let output_diff = newWidth/(total_outputs+1);
			for (let i = 0; i < total_outputs; i++){
				item_g.select('#' + item_opts.outputs[i].id)
					.attr("transform","translate(" + output_diff*(i+1) + "," + newHeight + ")" );
			}			

			self.updateItem(item_id, {
				width  : newWidth, 
				height : newHeight
			}); 

			for (let i=0; i < self.links.length; i++){
				
				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

		
		};

		var resizeHandle = item_g.append("rect")
			.attr("id", "resize-handle-" + item_opts.id)
			.attr("class", "mathflow-item-resize-handle")
			.attr("x", item_opts.width)
			.attr("y", item_opts.height)
			.attr("transform", "translate(-" + this.defs.items.resizehandle.width + ",-" + this.defs.items.resizehandle.height + ")")
			.attr("width", this.defs.items.resizehandle.width)
			.attr("height", this.defs.items.resizehandle.height)
			.attr("cursor", "nw-resize")
			.call(d3.drag()
				.on("start", resizeStart)
				.on("drag", resizing)
				.on("end", resizeEnd)
			);


		if (options.inputs !== undefined){
			this.addInputs(item_opts.id , options.inputs);
		}else{
			this.addInputs(item_opts.id , [
				{
					id   : "i_" + this.generateUUID(),
					text : "i_0",
					type : this.defs.items.inputs.types.double
				}
			]);	
		}

		if (options.outputs !== undefined){
			this.addOutputs(item_opts.id , options.outputs);
		}else{
			this.addOutputs(item_opts.id , [
				{
					id   : "o_" + this.generateUUID(),
					text : "o_0"
				}
			]);	
		}

		this.signalDiagramChangedEvent();

		return this;
	};

	MathFlow.prototype.addItemDecision = function(options){

		var self = this;
		
		var item_opts = {
			id      : (options.id      === undefined) ? "item_" + this.generateUUID() : options.id,
			name    : (options.name    === undefined) ? this.language.items.default_names.decision : options.name,
			x       : options.x, 
			y       : options.y,
			width   : (options.width    === undefined) ? 100 : options.width,
			height  : (options.height   === undefined) ? 100 : options.height,
			type    : options.type,
			inputs  : [],
			outputs : [],
			formula : (options.formula  === undefined) ? "" : options.formula,
			compare_operator : (options.compare_operator  === undefined) ? ">" : options.compare_operator,
			compare_value    : (options.compare_value     === undefined) ? 0 : Number(options.compare_value)

		};



		this.items.push(item_opts);

		
		var oldMousePosX = null;
		var oldMousePosY = null;
		var itemDragStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var itemDragEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var itemDragged = function() {
		

			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
			
			
			let currentX = aux_g_transform.translateX;//dimensions.x;//d3.select(this).attr("x");
			let currentY = aux_g_transform.translateY;//dimensions.y;//d3.select(this).attr("y");
		

			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){
				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}



			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;

			let newX = Math.max(0, Math.min(self.width() - item_opts.width, (incX + currentX) ) );
			let newY = Math.max(0, Math.min(self.height() - item_opts.height, (incY + currentY) ) );

			aux_g.attr("transform", "translate(" + newX + "," + newY + ") rotate(-45)" );
			
				
			for (let i=0; i < self.links.length; i++){

				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

			self.updateItem(item_id, {
				x : newX, 
				y : newY
			}); 

		
		};


		

	
		var item_g = this.svg.append("g")
			.attr("id", item_opts.id)
			.attr("class", "mathflow-item-group " + item_opts.type)
			.attr("transform", "translate(" + item_opts.x + "," + item_opts.y + ") rotate(-45)" );

		var item = item_g.append("rect")
			.attr("id", "rect-" + item_opts.id)
			.attr("x", 0) //item_opts.x)
			.attr("y", 0) //item_opts.y)
			.attr("class", "mathflow-item")
			.attr("width", item_opts.width)
			.attr("height", item_opts.height)
			.attr("cursor", "move")
			.call(d3.drag()
				.on("start", itemDragStart)
				.on("drag", itemDragged)
				.on("end", itemDragEnd)
			)
			.call(d3.clickCancel);

		item.on("dblclick",function(){
			// d3.select('#' + self.svg_id).selectAll(".mathflow-item").classed("selected-item", false);
			// d3.select('#' + self.svg_id).selectAll(".mathflow-item-resize-handle").classed("selected-item", false);
			// d3.select(this).classed("selected-item", true);
			// d3.select(this.parentNode).select(".mathflow-item-resize-handle").classed("selected-item", true);
			// self.showItemInfo(item_opts.id);
			d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
			d3.select(this.parentNode).classed("selected-item", true);
			self.showItemInfo(item_opts.id);
		});


		// Add the item name:
		var itemName = item_g.append("g")
			.attr("id", "name-" + item_opts.id)
			.attr("transform", "translate(" + (item_opts.width/2) + "," + (item_opts.height/2) + ") rotate(45)")
			.attr("class", "mathflow-item-name")
				.append("text")
					.attr("id",item_opts.id + "_name")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(item_opts.name);


		// Add the resize handle:
		var resizeStart = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};
		var resizeEnd = function(){
			oldMousePosX = null;
			oldMousePosY = null;
		};

		var resizing = function() {
		
			let item_id = item_opts.id;

			let aux_g = d3.select("#" + item_id);
			
			var aux_g_transform = d3.getTransformation(aux_g.attr("transform"));
						
			let currentX = aux_g_transform.translateX;
			let currentY = aux_g_transform.translateY;

			let aux_item         = aux_g.select("rect.mathflow-item");
			let aux_item_text_g  = aux_g.select(".mathflow-item-name");
			let aux_resizeHandle = aux_g.select("rect.mathflow-item-resize-handle");

			let currentWidth  = Number(aux_item.attr("width"));
			let currentHeight = Number(aux_item.attr("height"));


			
			let mousePosX = d3.event.sourceEvent.clientX;
			let mousePosY = d3.event.sourceEvent.clientY;

			if ((oldMousePosX === null) || (oldMousePosY === null)){

				oldMousePosX = mousePosX;
				oldMousePosY = mousePosY;
				return;
			}


			let incX = mousePosX - oldMousePosX;
			let incY = mousePosY - oldMousePosY;

			oldMousePosX = mousePosX;
			oldMousePosY = mousePosY;


			

			let newWidth  = Math.min(self.defs.items.dimensions.max_width, Math.max(self.defs.items.dimensions.min_width, currentWidth + incX));
			let newHeight = Math.min(self.defs.items.dimensions.max_height, Math.max(self.defs.items.dimensions.min_height, currentHeight + incY));



			if (currentX + newWidth > self.width() ){
				newWidth = self.width() - currentX;
			}

			if (currentY + newHeight > self.height() ){
				newHeight = self.height() - currentY;
			}

			if (newWidth<newHeight){
				newHeight = newWidth;
			}else{
				newWidth = newHeight;
			}

			aux_item.attr("width", newWidth);
			aux_item.attr("height", newHeight);
			aux_item_text_g.attr("transform", "translate(" + (newWidth/2) + "," + (newHeight/2) + ") rotate(45)");
			aux_resizeHandle.attr("x", (newWidth/2));
			aux_resizeHandle.attr("y", newHeight);


			// Update input positions:
			let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;
			for (let i = 0; i < total_inputs; i++){
				item_g.select('#' + item_opts.inputs[i].id)
					.attr("transform","translate(" + newWidth + ",0) rotate(45)" );
			}

			// Update output positions:
			let total_outputs = item_g.selectAll('.mathflow-output-group').nodes().length;
			
			for (let i = 0; i < total_outputs; i++){
				if ((item_opts.outputs[i].condition === true) || (item_opts.outputs[i].condition === "true") || (item_opts.outputs[i].condition === "1") || (item_opts.outputs[i].condition === 1)){
					item_g.select('#' + item_opts.outputs[i].id)
						.attr("transform","translate(0," + newHeight + ") rotate(45)" );	
				}else{
					item_g.select('#' + item_opts.outputs[i].id)
						.attr("transform","translate(" + newWidth + "," + newHeight + ") rotate(45)" );	
				}
			}			

			self.updateItem(item_id, {
				width  : newWidth, 
				height : newHeight
			}); 

			for (let i=0; i < self.links.length; i++){
				
				if ((d3.select(d3.select('#' + self.links[i].output_id).node().parentNode).attr("id") === item_id) || 
					(d3.select(d3.select('#' + self.links[i].input_id).node().parentNode).attr("id") === item_id)){
					self.recalculateConnector(self.links[i]);
				}

			}

		
		};

		var resizeHandle = item_g.append("rect")
			.attr("id", "resize-handle-" + item_opts.id)
			.attr("class", "mathflow-item-resize-handle")
			.attr("x", (item_opts.width/2))
			.attr("y", item_opts.height)
			.attr("transform", "translate(-" + (this.defs.items.resizehandle.width/2) + ",-" + this.defs.items.resizehandle.height + ")")
			.attr("width", this.defs.items.resizehandle.width)
			.attr("height", this.defs.items.resizehandle.height)
			.attr("cursor", "nw-resize")
			.call(d3.drag()
				.on("start", resizeStart)
				.on("drag", resizing)
				.on("end", resizeEnd)
			);


		if (options.inputs !== undefined){
			this.addInputs(item_opts.id , options.inputs);
		}else{

			this.addInputs(item_opts.id , [
				{
					id   : "i_" + this.generateUUID(),
					text : "i_0",
					type : this.defs.items.inputs.types.double
				}
			]);	
			this.updateItem(item_opts.id, {
				formula : "i_0"
			}); 
		}

		if (options.outputs !== undefined){
			this.addOutputs(item_opts.id , options.outputs);
		}else{
			this.addOutputs(item_opts.id , [
				{
					id        : "o_" + this.generateUUID(),
					text      : this.language.items.outputs.decision_yes,
					condition : true
				},
				{
					id        : "o_" + this.generateUUID(),
					text      : this.language.items.outputs.decision_no,
					condition : false
				}

			]);	
		}

		this.signalDiagramChangedEvent();

		return this;
	};

	MathFlow.prototype.recalculateConnector = function(link){

		let output_parent           = d3.select(this.svg.select("#" + link.output_id).node().parentNode);
		let input_parent            = d3.select(this.svg.select("#" + link.input_id).node().parentNode);

		let output_parent_transform = d3.getTransformation(d3.select(this.svg.select("#" + link.output_id).node().parentNode).attr("transform"));
		let output_transform        = d3.getTransformation(this.svg.select("#" + link.output_id).attr("transform"));
		let input_parent_transform  = d3.getTransformation(d3.select(this.svg.select("#" + link.input_id).node().parentNode).attr("transform"));
		let input_transform         = d3.getTransformation(this.svg.select("#" + link.input_id).attr("transform"));

		let output_coords = [output_parent_transform.translateX + output_transform.translateX, output_parent_transform.translateY + output_transform.translateY];
		let input_coords  = [input_parent_transform.translateX + input_transform.translateX, input_parent_transform.translateY + input_transform.translateY];


		if (output_parent_transform.rotate !== 0){

			output_coords = [
				output_parent_transform.translateX + (output_transform.translateX*Math.cos(Math.PI*(output_parent_transform.rotate)/180)) - (output_transform.translateY*Math.sin(Math.PI*(output_parent_transform.rotate)/180)), 
				output_parent_transform.translateY + (output_transform.translateX*Math.sin(Math.PI*(output_parent_transform.rotate)/180)) + (output_transform.translateY*Math.cos(Math.PI*(output_parent_transform.rotate)/180))
			];
		}

		if (input_parent_transform.rotate !== 0){
			
			input_coords = [
				input_parent_transform.translateX + (input_transform.translateX*Math.cos(Math.PI*(input_parent_transform.rotate)/180)) - (input_transform.translateY*Math.sin(Math.PI*(input_parent_transform.rotate)/180)), 
				input_parent_transform.translateY + (input_transform.translateX*Math.sin(Math.PI*(input_parent_transform.rotate)/180)) + (input_transform.translateY*Math.cos(Math.PI*(input_parent_transform.rotate)/180))
			];
		}

		
		link.output_coords = output_coords;
		link.input_coords  = input_coords;
		
		let line_interpolation = this.defs.connectors.curved_line_interpolation;
		let dataArray = [
			{
				x : output_coords[0], 
				y : output_coords[1]
			}
		];

		let prop = Math.floor((Math.random() * (8 - 2)) + 2)/10;

		if ((output_coords[0] === input_coords[0]) || (output_coords[1] === input_coords[1])){
			line_interpolation = this.defs.connectors.straight_line_interpolation;
			
			dataArray.push({
				x : input_coords[0], 
				y : input_coords[1]
			});
		}else{

			if (!output_parent.classed(this.defs.items.types.decision)){

				dataArray.push({
					x : output_coords[0], 
					//y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});

			}else if ((input_coords[0] > output_coords[0]) && (input_coords[1] > output_coords[1])){

				dataArray.push({
					x : input_coords[0], 
					y : output_coords[1]
				});


				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});
				

			}else{
				dataArray.push({
					x : output_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});
					
			}

			
		}



		let line = d3.line()
		    .x(function(d) { return (d.x); })
		    .y(function(d) { return (d.y); })
		    .curve(line_interpolation);

		this.svg.select("#" + link.id)
			.data([dataArray])
	            .attr("d", line);

	    this.signalDiagramChangedEvent();

	};

	MathFlow.prototype.recalculateConnectorByInputId = function(input_id){

		let i = 0;
		let link_index = null;
		for (i=0 ; i<this.links.length ; i++){
			if (this.links[i].input_id === input_id){
				link_index = i;
				break;
			}
		}

		if (link_index === null){
			return;
		}

		let link = this.links[link_index];

		this.recalculateConnector(link);

	
	};

	MathFlow.prototype.recalculateConnectorByOutputId = function(output_id){

		let i = 0;
		let link_index = null;
		for (i=0 ; i<this.links.length ; i++){
			if (this.links[i].output_id === output_id){
				link_index = i;
				break;
			}
		}

		if (link_index === null){
			return;
		}

		let link = this.links[link_index];

		this.recalculateConnector(link);

	};


	MathFlow.prototype.addConnector = function(output_id, input_id){



		var self = this;

		let output_parent           = d3.select(this.svg.select("#" + output_id).node().parentNode);
		let input_parent            = d3.select(this.svg.select("#" + input_id).node().parentNode);

		let output_item_id          = output_parent.attr("id");
		let input_item_id           = input_parent.attr("id");

		let output_item_type        = ""; 
		let input_item_type         = ""; 

		for (let i=0; i < this.items.length; i++){
			if (this.items[i].id === input_item_id){
				input_item_type = this.items[i].type;
			}
			if (this.items[i].id === output_item_id){
				output_item_type = this.items[i].type;
			}
		}

		if (output_id === input_id){
			return;
		}


		for (let i=0; i < this.links.length; i++){

			// Already exists:
			if ((this.links[i].output_id === output_id) && (this.links[i].input_id === input_id)){
				return;
			}

			// input is in use, and input is process node:
			if ((this.links[i].input_id === input_id) && (input_item_type === this.defs.items.types.process)){
				return;
			}

			// input is in use, and input is decision node:
			if ((this.links[i].input_id === input_id) && (input_item_type === this.defs.items.types.decision)){
				return;
			}
			
		}

		if (input_parent.attr("id") === output_parent.attr("id")){
			return;
		}

		let output_parent_transform = d3.getTransformation(output_parent.attr("transform"));
		let output_transform        = d3.getTransformation(this.svg.select("#" + output_id).attr("transform"));

		let input_parent_transform  = d3.getTransformation(input_parent.attr("transform"));
		let input_transform         = d3.getTransformation(this.svg.select("#" + input_id).attr("transform"));


		let output_coords = [output_parent_transform.translateX + output_transform.translateX, output_parent_transform.translateY + output_transform.translateY];
		let input_coords  = [input_parent_transform.translateX + input_transform.translateX, input_parent_transform.translateY + input_transform.translateY];
		 

		if (output_parent_transform.rotate !== 0){

			output_coords = [
				output_parent_transform.translateX + (output_transform.translateX*Math.cos(Math.PI*(output_parent_transform.rotate)/180)) - (output_transform.translateY*Math.sin(Math.PI*(output_parent_transform.rotate)/180)), 
				output_parent_transform.translateY + (output_transform.translateX*Math.sin(Math.PI*(output_parent_transform.rotate)/180)) + (output_transform.translateY*Math.cos(Math.PI*(output_parent_transform.rotate)/180))
			];
		}

		if (input_parent_transform.rotate !== 0){
			
			input_coords = [
				input_parent_transform.translateX + (input_transform.translateX*Math.cos(Math.PI*(input_parent_transform.rotate)/180)) - (input_transform.translateY*Math.sin(Math.PI*(input_parent_transform.rotate)/180)), 
				input_parent_transform.translateY + (input_transform.translateX*Math.sin(Math.PI*(input_parent_transform.rotate)/180)) + (input_transform.translateY*Math.cos(Math.PI*(input_parent_transform.rotate)/180))
			];
		}
		
		
		let new_link = {
			id             : this.generateUUID(),
			output_id      : output_id,
			output_item_id : output_item_id,
			input_id       : input_id,
			input_item_id  : input_item_id,
			output_coords  : output_coords,
			input_coords   : input_coords,

		};

				

		let line_interpolation = this.defs.connectors.curved_line_interpolation;

		let dataArray = [
			{
				x : output_coords[0], 
				y : output_coords[1]
			}
		];

		let prop = Math.floor((Math.random() * (8 - 2)) + 2)/10;

		if ((output_coords[0] === input_coords[0]) || (output_coords[1] === input_coords[1])){
			line_interpolation = this.defs.connectors.straight_line_interpolation;

			dataArray.push({
				x : input_coords[0], 
				y : input_coords[1]
			});
		}else{

			if (!output_parent.classed(this.defs.items.types.decision)){

				dataArray.push({
					x : output_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});

			}else if ((input_coords[0] > output_coords[0]) && (input_coords[1] > output_coords[1])){

				dataArray.push({
					x : input_coords[0], 
					y : output_coords[1]
				});


				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});
				

			}else{
				dataArray.push({
					x : output_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					// y : (input_coords[1] + output_coords[1])*prop
					y : input_coords[1]*prop + output_coords[1]*(1-prop)
				});

				dataArray.push({
					x : input_coords[0], 
					y : input_coords[1]
				});
					
			}

			
		}


		let line = d3.line()
		    .x(function(d) { return (d.x); })
		    .y(function(d) { return (d.y); })
		    .curve(line_interpolation);

		var new_path = this.svg.select("g.mathflow-paths").append("path")
			.attr('id', new_link.id)
			.attr('class', 'mathflow-link')
			.data([dataArray])
            .attr("d", line)
            .attr("marker-end","url(#" + self.marker_arrow_id + ")")
			.call(d3.curveBundle);

		new_path.on('click', function(){
			self.removeConnector(new_link.id);
		});

		this.links.push(new_link); 

		this.svg.selectAll("g.mathflow-output-group").classed("active",false);

		this.signalDiagramChangedEvent();

	};

	

	MathFlow.prototype.removeConnector = function(link_id){
		let remove_index = null;
		for (let i = 0 ; i < this.links.length ; i++){
			if (this.links[i].id === link_id){
				
				this.svg.select("#" + link_id).remove();
				remove_index = i;
				break;				
			}
		}

		if (remove_index !== null){
			this.links.splice(remove_index,1);
		}

		this.signalDiagramChangedEvent();
		
	};

	MathFlow.prototype.removeConnectorByInputId = function(input_id, signal_event){
		let remove_index = [];

		if (signal_event === undefined){
			signal_event = true;
		}

		for (let i = 0 ; i < this.links.length ; i++){
			if (this.links[i].input_id === input_id){
				let link_id = this.links[i].id;
				this.svg.select("#" + link_id).remove();
				remove_index.push(i);
			}
		}

		if (remove_index.length > 0){
			for (let i = remove_index.length-1 ; i >= 0  ; i--){
				this.links.splice(remove_index[i],1);
			}
		}
	
		if (signal_event){
			this.signalDiagramChangedEvent();
		}

	};

	MathFlow.prototype.removeConnectorByOutputId = function(output_id, signal_event){

		let remove_index = [];

		if (signal_event === undefined){
			signal_event = true;
		}

		for (let i = 0 ; i < this.links.length ; i++){
			if (this.links[i].output_id === output_id){
				let link_id = this.links[i].id;
				this.svg.select("#" + link_id).remove();
				remove_index.push(i);
			}
		}

		if (remove_index.length > 0){
			for (let i = remove_index.length-1 ; i >= 0  ; i--){
				this.links.splice(remove_index[i],1);
			}
		}

		if (signal_event){
			this.signalDiagramChangedEvent();
		}

	};

	MathFlow.prototype.updateItem = function(item_id, new_options){

		
		let i=0;
		let j=0;
		
		for (i=0; i < this.items.length; i++){
			
			
			if (this.items[i].id === item_id){
				for (var key in new_options){

					switch (key){
						case "formula" :
							this.items[i][key] = new_options[key];

						break;
						case "compare_value" :
							this.items[i][key] = Number(new_options[key]);

						break;
						case "name":
							d3.select("#" + item_id + "_name").text(new_options[key]);
							this.items[i][key] = new_options[key];
						break;

						case "input":
							for (j=0; j<this.items[i].inputs.length; j++){
								if (this.items[i].inputs[j].id === new_options[key].id){
									
									for (var key2 in new_options[key]){
										this.items[i].inputs[j][key2] =new_options[key][key2]; 

										if (key2 === "text"){
											d3.select("#" + this.items[i].inputs[j].id + " text").text(new_options[key][key2]);
										}
									}									
									
									break;
								}
							}
							
						break;

						case "output":
							for (j=0; j<this.items[i].outputs.length; j++){
								if (this.items[i].outputs[j].id === new_options[key].id){
									
									for (var key2 in new_options[key]){
										this.items[i].outputs[j][key2] =new_options[key][key2]; 

										if (key2 === "text"){
											d3.select("#" + this.items[i].outputs[j].id + " text").text(new_options[key][key2]);
										}
									}									
									
									break;
								}
							}
							
						break;

						default:
							this.items[i][key] = new_options[key];
						break;
					}

				}		
				break;
			}
		}

		this.signalDiagramChangedEvent();
		
	};

	

	MathFlow.prototype.addInputs = function(item_id , new_inputs){


				
		var self = this;
		let i = 0;

		let new_inputs_array = [];
		if (!Array.isArray(new_inputs)){
			new_inputs_array.push(new_inputs);
		}else{
			new_inputs_array = new_inputs;
		}


		let target_item_index = null;

		for (i=0; i < this.items.length; i++){
			if (this.items[i].id === item_id){
				target_item_index = i;
				break;
			}
		}

		if (target_item_index === null){
			return;
		}


		let item_type = this.items[target_item_index].type;
		
		let item_g = this.svg.select('#' + item_id);

				
		function inputClick(id){
			return function(){
				let active_outputs = self.svg.selectAll("g.mathflow-output-group.active").each(function(){
					self.addConnector(d3.select(this).attr("id"), id);
				});
			}
		}

		

		for (i = 0; i < new_inputs_array.length ; i++){

			let new_input = {
				id   : (new_inputs_array[i].id === undefined) ? "i_" + this.generateUUID() :  new_inputs_array[i].id,
				text : new_inputs_array[i].text,
				type : (new_inputs_array[i].type === undefined) ? this.defs.items.inputs.types.double : new_inputs_array[i].type,
			};



			this.items[target_item_index].inputs.push(new_input);

						
			let aux_text_g = item_g.append("g")
				.attr("id", new_input.id )
				.attr("class","mathflow-input-group");
				
			aux_text_g.append("circle")
				.attr("cx", 0)
				.attr("cy", 0)
				.attr("r" , 5);

			if (item_type !== this.defs.items.types.decision){
				aux_text_g.append("text")
					.attr("x", 0)
					.attr("y", 15)
					.attr("text-anchor", "middle")
					.text(new_input.text);
			}else{
				aux_text_g.append("text")
					.attr("x", 0)
					.attr("y", 25)
					.attr("text-anchor", "middle")
					.text(new_input.text);
			}

			aux_text_g.on("click", inputClick(new_input.id));

			


		}

		
		// Update input positions:
		if (item_type !== this.defs.items.types.decision){
			let input_diff = this.items[target_item_index].width/(this.items[target_item_index].inputs.length+1);
			let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;
			for (i = 0; i < total_inputs; i++){
				item_g.select('#' + this.items[target_item_index].inputs[i].id)
					.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
				this.recalculateConnectorByInputId(this.items[target_item_index].inputs[i].id);

			}	

		}else{

			// Must have only one input:
			item_g.select('#' + this.items[target_item_index].inputs[0].id)
					.attr("transform","translate(" + this.items[target_item_index].width + ",0) rotate(45)" );

		} 
		

		this.signalDiagramChangedEvent();

		

		return this;

	};

	MathFlow.prototype.addOutputs = function(item_id , new_outputs){

				
		var self = this;
		let i = 0;


		let new_outputs_array = [];
		if (!Array.isArray(new_outputs)){
			new_outputs_array.push(new_outputs);
		}else{
			new_outputs_array = new_outputs;
		}

		let target_item_index = null;

		for (i=0; i < this.items.length; i++){
			if (this.items[i].id === item_id){
				target_item_index = i;
				break;
			}
		}

		if (target_item_index === null){
			return;
		}

		
		let item_type = this.items[target_item_index].type;

		let item_g = this.svg.select('#' + item_id);

				
		function outputClick(id){
			return function(){
				if (d3.select(this).classed("active")){
					
					d3.select(this).classed("active",false);

				}else{

					// Remove the rest:
					self.svg.selectAll("g.mathflow-output-group").classed("active",false);

					d3.select(this).classed("active",true);

					let active_inputs = self.svg.selectAll("g.mathflow-input-group").nodes();
				}
			}
		}

		

		for (i = 0; i < new_outputs_array.length ; i++){

			let new_output = {
				id   : (new_outputs_array[i].id === undefined) ? "o_" + this.generateUUID() :  new_outputs_array[i].id,
				text : new_outputs_array[i].text,
				type : (new_outputs_array[i].type === undefined) ? this.defs.items.outputs.types.double : new_outputs_array[i].type,
			};

			if (new_outputs_array[i].condition !== undefined){
				new_output.condition = new_outputs_array[i].condition;
			}

			this.items[target_item_index].outputs.push(new_output);

						
			let aux_text_g = item_g.append("g")
				.attr("id", new_output.id )
				.attr("class","mathflow-output-group");
				
			aux_text_g.append("circle")
				.attr("cx", 0)
				.attr("cy", 0)
				.attr("r" , 5);
			if (item_type !== this.defs.items.types.decision){
				aux_text_g.append("text")
					.attr("x", 0)
					.attr("y", -10)
					.attr("text-anchor", "middle")
					.text(new_output.text);
			}else{
				aux_text_g.append("text")
					.attr("x", 0)
					.attr("y", -20)
					.attr("text-anchor", "middle")
					.text(new_output.text);
			}

			aux_text_g.on("click", outputClick(new_output.id));

			


		}

		
		// Update output positions:
		if (item_type !== this.defs.items.types.decision){

			let output_diff = this.items[target_item_index].width/(this.items[target_item_index].outputs.length+1);
			let total_outputs = item_g.selectAll('.mathflow-output-group').nodes().length;
			for ( i = 0; i < total_outputs; i++){
				item_g.select('#' + this.items[target_item_index].outputs[i].id)
					.attr("transform","translate(" + output_diff*(i+1) + "," + this.items[target_item_index].height  + ")" );
			}

		}else{

			// Must have only two outputs:
			item_g.select('#' + this.items[target_item_index].outputs[0].id)
					.attr("transform","translate(0," + this.items[target_item_index].height  + ") rotate(45)" );

			item_g.select('#' + this.items[target_item_index].outputs[1].id)
					.attr("transform","translate(" + this.items[target_item_index].width + "," + this.items[target_item_index].height  + ") rotate(45)" );


		}

		return this.signalDiagramChangedEvent();

		// return this;

	};

	MathFlow.prototype.removeItem = function(item_id ){

				
		var self = this;
		let i = 0;

		
		let target_item_index = null;

		for (i=0; i < this.items.length; i++){
			if (this.items[i].id === item_id){
				target_item_index = i;
				break;
			}
		}

		if (target_item_index === null){
			return;
		}


		for (i=0; i<this.items[target_item_index].inputs.length; i++){
			this.removeConnectorByInputId(this.items[target_item_index].inputs[i].id, false);
		}
		for (i=0; i<this.items[target_item_index].outputs.length; i++){
			this.removeConnectorByOutputId(this.items[target_item_index].outputs[i].id, false);
		}

		// Now remove the graphical element:
		this.svg.select('#' + item_id).remove();

		// Remove from array:
		this.items.splice(target_item_index,1);
		
		this.signalDiagramChangedEvent();

		return this;

	};

	MathFlow.prototype.removeInputs = function(item_id , input_ids){

				
		var self = this;
		let i = 0;


		let input_ids_array = [];
		if (!Array.isArray(input_ids)){
			input_ids_array.push(input_ids);
		}else{
			input_ids_array = new_inputs;
		}


		let target_item_index = null;

		for (i=0; i < this.items.length; i++){
			if (this.items[i].id === item_id){
				target_item_index = i;
				break;
			}
		}

		if (target_item_index === null){
			return;
		}

		// Remove all elements from inputs array:
		this.items[target_item_index].inputs = this.items[target_item_index].inputs.filter(function(element){

			if (input_ids_array.indexOf(element.id) !== -1){
				return false;
			}else{
				return true;
			}

		});

		// Now remove all graphical inputs and connectors:
		let item_g = this.svg.select('#' + item_id);
		for (i=0; i<input_ids_array.length; i++){
			item_g.select("#" + input_ids_array[i]).remove();
			this.removeConnectorByInputId(input_ids_array[i]);
		}
		
		
		
		// Update input positions:
		let input_diff = this.items[target_item_index].width/(this.items[target_item_index].inputs.length+1);
		let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;

		for (i = 0; i < total_inputs; i++){
			item_g.select('#' + this.items[target_item_index].inputs[i].id)
				.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
			this.recalculateConnectorByInputId(this.items[target_item_index].inputs[i].id);
		}


		
		this.signalDiagramChangedEvent();

		return this;

	};

	MathFlow.prototype.removeOutputs = function(item_id , output_ids){

				
		var self = this;
		let i = 0;

		let output_ids_array = [];
		if (!Array.isArray(output_ids)){
			output_ids_array.push(output_ids);
		}else{
			output_ids_array = new_inputs;
		}

		let target_item_index = null;

		for (i=0; i < this.items.length; i++){
			if (this.items[i].id === item_id){
				target_item_index = i;
				break;
			}
		}

		if (target_item_index === null){
			return;
		}


		// Remove all elements from outputs array:
		this.items[target_item_index].outputs = this.items[target_item_index].outputs.filter(function(element){

			if (output_ids_array.indexOf(element.id) !== -1){
				return false;
			}else{
				return true;
			}

		});

		// Now remove all graphical outputs and connectors:
		let item_g = this.svg.select('#' + item_id);
		for (i=0; i<output_ids_array.length; i++){
			item_g.select("#" + output_ids_array[i]).remove();
			this.removeConnectorByOutputId(output_ids_array[i]);
		}
		
		
		
		// Update input positions:
		let input_diff = this.items[target_item_index].width/(this.items[target_item_index].outputs.length+1);
		let total_inputs = item_g.selectAll('.mathflow-input-group').nodes().length;

		for (i = 0; i < total_inputs; i++){
			item_g.select('#' + this.items[target_item_index].outputs[i].id)
				.attr("transform","translate(" + input_diff*(i+1) + ",0)" );
			this.recalculateConnectorByOutputId(this.items[target_item_index].outputs[i].id);
		}


		
		this.signalDiagramChangedEvent();

		return this;

	};



	MathFlow.prototype.signalDiagramChangedEvent = function(){
		$(this.container).trigger(this.defs.events.types.diagramChanged, {
			svg_container_id : this.svg_container_id,
			svg_id : this.svg_id,
			name   : this.name, 
			items  : this.items,
			links  : this.links 	
		});

		return this;
	};

	MathFlow.prototype.showItemInfo = function(item_id){
		
		var self = this;

		$(this.container).find('.mathflow-item-info').html("");

		$(this.container).find('.mathflow-item-info').addClass("active");


		let item_name = "";
		let process_formula = "";
		let decision_formula = "";
		let compare_value = 0;
		let compare_operator = "";
		let type = "";
		let is_editable = true;


		let inputs = [];
		let outputs = [];

		let the_target_item = null;

		for (let i = 0 ; i < this.items.length ; i++){

			
			if (this.items[i].id === item_id){

				if ((this.items[i].is_editable !== undefined) && (this.items[i].is_editable === false)){
					return;
				}

				the_target_item = this.items[i];
				
				item_name = this.items[i].name;
				type = this.items[i].type;
				inputs = this.items[i].inputs;
				outputs = this.items[i].outputs;



				if (type === this.defs.items.types.process){
					process_formula = this.items[i].formula;	
				}else if (type === this.defs.items.types.decision){
					compare_value = this.items[i].compare_value;	
					compare_operator = this.items[i].compare_operator;	
					decision_formula = this.items[i].formula;	
				}

				
				
				break;
		
			}
		}

		let item_input_html = '';
		
		switch (type){
			case this.defs.items.types.process:
				item_input_html += '<div class="row">';
				item_input_html += '<div class="col-md-offset-3 col-md-6 mathflow-item-info-container modal-content">';

				item_input_html += '<div class="modal-header">';
				item_input_html += 		'<button type="button" class="close" data-dismiss="modal">×</button><h4 class="modal-title">' + this.language.items.edit + '</h4>';
				item_input_html += '</div>';

				item_input_html += '<div class="modal-body">';

				item_input_html += '<div class="row mathflow-item-info-element">';
				item_input_html += 		'<div class="control-group col-md-12">';
		        item_input_html +=      	'<label class="control-label" for="item_name">' + this.language.items.enter_name + ':</label>';
		        item_input_html +=          '<div class="controls">';
		        item_input_html +=          	'<input type="text" class="form-control mathflow-item-input-name" value="'+ item_name +'">';
		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
				item_input_html += '</div>';

				item_input_html += '<div class="row mathflow-item-info-element">';
				item_input_html += 		'<div class="control-group col-md-6">';
		        item_input_html +=      	'<label class="control-label" for="process_formula">' + this.language.items.enter_formula + ':</label>';
		        item_input_html +=          '<div class="controls">';
		        item_input_html +=          	'<input type="text" class="form-control mathflow-item-input-formula" value="'+ process_formula +'">';
		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
		        item_input_html += 		'<div class="control-group col-md-6">';
		        item_input_html +=      	'<label class="control-label" for="preview_formula">' + this.language.items.preview_formula + ':</label>';
		        item_input_html +=          '<div class="controls rendered_formula">';

		        item_input_html += '$$' + process_formula.replace(/_/g, "\\_").replace(/%/g, "\\%") + '$$';

		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
				item_input_html += '</div>';

				item_input_html += '<div class="row mathflow-item-info-element" >';
				item_input_html += 		'<div class="control-group col-md-12">';
		        item_input_html +=      	'<label class="control-label" for="process_formula">Inputs:</label>';
		        item_input_html +=          '<div class="controls">';

		        item_input_html += 				'<table class="table table-bordered">';
		        item_input_html += 					'<thead>';
		        item_input_html += 						'<tr class="input-list-item">';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.input;
		        item_input_html += 							'</th>';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.type;
		        item_input_html += 							'</th>';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.actions;
		        item_input_html += 							'</th>';
		        item_input_html += 						'</tr>';
		        item_input_html += 					'</thead>';
				item_input_html += 					'<tbody>';
				for (let i = 0 ; i < inputs.length ; i++){
					item_input_html += 					'<tr class="input-list-item">';
					item_input_html += 						'<td class="input-text">'; 
					// item_input_html +=          				'<input type="text" class="form-control" data-input-id="'+ inputs[i].id +'" value="'+ inputs[i].text +'">';
					item_input_html +=          				inputs[i].text;
					item_input_html +=          			'</td>';

					let disabled = "";
					if ((i===0) ||(i < inputs.length-1)){
						disabled = ' disabled= "disabled" ';
					}
					item_input_html += 						'<td>';
					item_input_html += 							'<select data-input-id="'+ inputs[i].id + '"  class="form-control mathflow-item-select-type">';
	  				item_input_html += 								'<option value="' + this.defs.items.inputs.types.double + '"   ' + ((inputs[i].type === this.defs.items.inputs.types.double)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.double  + '</option>';
	  				item_input_html += 								'<option value="' + this.defs.items.inputs.types.integer + '"   ' + ((inputs[i].type === this.defs.items.inputs.types.integer)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.integer  + '</option>';
	  				item_input_html += 								'<option value="' + this.defs.items.inputs.types.timestamp + '"   ' + ((inputs[i].type === this.defs.items.inputs.types.timestamp)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.timestamp  + '</option>';
					item_input_html += 							'</select>';
					item_input_html += 						'</td>';

					item_input_html += 						'<td><button type="button" ' + disabled +  ' data-input-id="'+ inputs[i].id +'" class="btn btn-danger btn-remove-input ' + this.language.items.inputs.remove_input_icon + '" ></button></td>';
					
					item_input_html += 					'</tr>';
				}
				item_input_html += 					'</tbody>';
		        item_input_html += 					'<tfoot>';
		        item_input_html += 						'<tr class="add-input">';
		       	item_input_html += 							'<th colspan="2">' + this.language.items.inputs.add_input + ':</th>';
		       	item_input_html += 							'<th><button type="button" class="btn btn-success btn-add-input ' + this.language.items.inputs.add_input_icon + '" ></button></th>';
	        	item_input_html += 						'</tr>';
				item_input_html += 					'</tfoot>';
				item_input_html += 				'</table>';

		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
		        
				item_input_html += '</div>';
				item_input_html += '</div>'; // modal body

				item_input_html += '<div class="modal-footer">';
				item_input_html += 		'<div class="btn-group">';
				item_input_html += 			'<button type="button" class="btn btn-danger remove-item mdi mdi-delete">' + this.language.items.remove + '</button>';
				item_input_html += 			'<button type="button" class="btn btn-default close-item-info-dialog">' + this.language.items.close + '</button>';
				item_input_html += 		'</div>';
				item_input_html += '</div>';

				item_input_html += '</div>';
				item_input_html += '</div>';



				
				$(this.container).find('.mathflow-item-info').html(item_input_html);

				MathJax.Hub.Queue(["Typeset", MathJax.Hub, $(self.container).find(".rendered_formula")[0]]);
				
				$(this.container).find('.mathflow-item-info input.mathflow-item-input-formula').on('change', function(){
					let new_formula = $(this).val();
					let escaped_formula = new_formula.replace(/_/g, "\\_").replace(/%/g, "\\%");
					
					$(self.container).find(".rendered_formula").html('$$' + escaped_formula + '$$');
					
					MathJax.Hub.Queue(["Typeset", MathJax.Hub, $(self.container).find(".rendered_formula")[0]]);

					self.updateItem(item_id, {
						formula : new_formula
					});
				});

				$(this.container).find('.mathflow-item-info .modal-header button.close, .mathflow-item-info .modal-footer button.close-item-info-dialog').on('click', function(){
					
					$(self.container).find('.mathflow-item-info').removeClass('active');
					$(self.container).find('.mathflow-item-info').html('');
					d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);

				});

				$(this.container).find('.mathflow-item-info .modal-footer button.remove-item').on('click', function(){
					
					$(self.container).find('.mathflow-item-info').removeClass('active');
					$(self.container).find('.mathflow-item-info').html('');
					d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);

					self.removeItem(item_id);

				});


				$(this.container).find('.mathflow-item-info select.mathflow-item-select-type').on('change', function(){
					let new_input_type = $(this).val();
					let input_id = $(this).data("input-id");

					self.updateItem(item_id, {
						input : {
							id   : input_id,
							type : new_input_type
						}

					});
				});

				$(this.container).find('.mathflow-item-info input.mathflow-item-input-name').on('change', function(){
					let new_name = $(this).val();

					self.updateItem(item_id, {
						name : new_name
					});
				});

				$(this.container).find('.mathflow-item-info button.btn-remove-input').on('click', function(){
					let input_id = $(this).data("input-id");
					self.removeInputs(item_id, input_id);
					
					let table = $(this).closest('table');
					$(this).closest('tr').remove();
					table.find('tr.input-list-item').last().find('button.btn-remove-input').prop("disabled",false);

				});

				$(this.container).find('.mathflow-item-info button.btn-add-input').on('click', function(){


					let new_text = "i_" + ($(this).closest('table').find('.input-list-item').length-1);
					let new_input_id = "i_" + self.generateUUID();
					let new_type = self.defs.items.inputs.types.double;

					let item_input_html = '';
					item_input_html += 	'<tr class="input-list-item">';
					item_input_html += 		'<td class="input-text">'; 
					// item_input_html +=      	'<input type="text" class="form-control" data-input-id="'+ new_input_id +'" value="'+ new_text +'">';
					item_input_html +=      	new_text;
					item_input_html +=      '</td>';
					item_input_html += 		'<td>';
					item_input_html += 			'<select data-input-id="'+ new_input_id + '"  class="form-control mathflow-item-select-type">';
	  				item_input_html += 				'<option value="' + self.defs.items.inputs.types.double + '"   ' + ((new_type === self.defs.items.inputs.types.double)? 'selected="selected"' : '') + '>' + self.language.items.inputs.types.double  + '</option>';
	  				item_input_html += 				'<option value="' + self.defs.items.inputs.types.integer + '"   ' + ((new_type === self.defs.items.inputs.types.integer)? 'selected="selected"' : '') + '>' + self.language.items.inputs.types.integer  + '</option>';
	  				item_input_html += 				'<option value="' + self.defs.items.inputs.types.timestamp + '"   ' + ((new_type === self.defs.items.inputs.types.timestamp)? 'selected="selected"' : '') + '>' + self.language.items.inputs.types.timestamp  + '</option>';
					item_input_html += 			'</select>';
					item_input_html += 		'</td>';
					item_input_html += 		'<td><button type="button" data-input-id="'+ new_input_id +'" class="btn btn-danger btn-remove-input ' + self.language.items.inputs.remove_input_icon + '" ></button></td>';
					item_input_html += 	'</tr>';

					$(this).closest('table').find('.input-list-item button.btn-remove-input').attr("disabled", "disabled");
					$(this).closest('tr').before(item_input_html);

					$(self.container).find('.mathflow-item-info button.btn-remove-input[data-input-id="' + new_input_id + '"]').on('click', function(){
						let input_id = $(this).data("input-id");
						self.removeInputs(item_id, input_id);


						let table = $(this).closest('table');
						$(this).closest('tr').remove();
						table.find('tr.input-list-item').last().find('button.btn-remove-input').prop("disabled",false);

					});

					$(self.container).find('.mathflow-item-info select.mathflow-item-select-type[data-input-id="' + new_input_id + '"]').on('change', function(){
						let new_input_type = $(this).val();
						let input_id = $(this).data("input-id");

						self.updateItem(item_id, {
							input : {
								id   : input_id,
								type : new_input_type
							}

						});
					});



					self.addInputs(item_id, {
						id   : new_input_id,
						text : new_text,
						type : new_type
					});
					
				});
		
		

			break;

			case this.defs.items.types.decision:
				item_input_html += '<div class="row">';
				item_input_html += '<div class="col-md-offset-3 col-md-6 mathflow-item-info-container modal-content">';

				item_input_html += '<div class="modal-header">';
				item_input_html += 		'<button type="button" class="close" data-dismiss="modal">×</button><h4 class="modal-title">' + this.language.items.edit + '</h4>';
				item_input_html += '</div>';

				item_input_html += '<div class="modal-body">';

				item_input_html += '<div class="row mathflow-item-info-element">';
				item_input_html += 		'<div class="control-group col-md-12">';
		        item_input_html +=      	'<label class="control-label" for="item_name">' + this.language.items.enter_name + ':</label>';
		        item_input_html +=          '<div class="controls">';
		        item_input_html +=          	'<input type="text" class="form-control mathflow-item-input-name" value="'+ item_name +'">';
		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
				item_input_html += '</div>';

				item_input_html += '<div class="row mathflow-item-info-element">';
				item_input_html += 		'<div class="control-group col-md-6">';
		        item_input_html +=      	'<label class="control-label" for="decision_formula">' + this.language.items.enter_formula + ':</label>';
		        item_input_html +=          '<div class="controls">';
		        item_input_html +=          	'<input type="text" class="form-control mathflow-item-input-formula" value="'+ decision_formula +'">';
		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
		        item_input_html += 		'<div class="control-group col-md-6">';
		        item_input_html +=      	'<label class="control-label" for="preview_formula">' + this.language.items.preview_formula + ':</label>';
		        item_input_html +=          '<div class="controls rendered_formula">';

		        
		        item_input_html += '$$' + decision_formula.replace(/_/g, "\\_").replace(/%/g, "\\%") + '$$';

		        item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
				item_input_html += '</div>';


				item_input_html += '<div class="row mathflow-item-info-element">';
				item_input_html += 		'<div class="control-group col-md-12">';
		        item_input_html +=      	'<label class="control-label" for="item_name">' + this.language.items.enter_condition + ':</label>';
		        item_input_html +=          '<div class="controls">';

				item_input_html += 				'<table class="table table-bordered">';
				item_input_html += 					'<thead>';
		        item_input_html += 						'<tr class="input-list-item">';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.input;
		        item_input_html += 							'</th>';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.type;
		        item_input_html += 							'</th>';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.operator;
		        item_input_html += 							'</th>';
		        item_input_html += 							'<th class="header">';
		        item_input_html += 								this.language.items.inputs.compare_value;
		        item_input_html += 							'</th>';
		        item_input_html += 						'</tr>';
		        item_input_html += 					'</thead>';
				item_input_html += 					'<tbody>';
				item_input_html += 						'<tr class="input-list-item">';
				

				item_input_html += 							'<td class="input-text">'; 
				item_input_html += 								inputs[0].text;
				item_input_html +=          				'</td>';

				item_input_html += 							'<td>';
				item_input_html += 								'<select data-input-id="'+ inputs[0].id + '"  class="form-control mathflow-item-select-type">';
	  			item_input_html += 									'<option value="' + this.defs.items.inputs.types.double + '"   ' + ((inputs[0].type === this.defs.items.inputs.types.double)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.double  + '</option>';
	  			item_input_html += 									'<option value="' + this.defs.items.inputs.types.integer + '"   ' + ((inputs[0].type === this.defs.items.inputs.types.integer)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.integer  + '</option>';
	  			item_input_html += 									'<option value="' + this.defs.items.inputs.types.timestamp + '"   ' + ((inputs[0].type === this.defs.items.inputs.types.timestamp)? 'selected="selected"' : '') + '>' + this.language.items.inputs.types.timestamp  + '</option>';
				item_input_html += 								'</select>';
				item_input_html += 							'</td>';

				item_input_html += 							'<td class="input-operator">'; 

				item_input_html += 								'<select class="form-control mathflow-item-compare-operator">';
				item_input_html += 									'<option value=">"  ' + ((compare_operator === ">")? "selected" : "") + '>&gt;</option>';
				item_input_html += 									'<option value=">=" ' + ((compare_operator === ">=")? "selected" : "") + '>&ge;</option>';
				item_input_html += 									'<option value="==" ' + ((compare_operator === "==")? "selected" : "") + '>==</option>';
				item_input_html += 									'<option value="<=" ' + ((compare_operator === "<=")? "selected" : "") + '>&le;</option>';
				item_input_html += 									'<option value="<"  ' + ((compare_operator === "<")? "selected" : "") + '>&lt;</option>';
				item_input_html += 									'<option value="!=" ' + ((compare_operator === "!=")? "selected" : "") + '>!=</option>';
				item_input_html += 								'</select>';

				item_input_html +=          				'</td>';
				
				item_input_html += 							'<td class="input-text">'; 
				item_input_html +=          					'<input type="text" class="form-control mathflow-item-input-compare-value" value="'+ compare_value +'">';
				item_input_html +=          				'</td>';

				item_input_html += 						'</tr>';
				item_input_html += 					'</tbody>';
				item_input_html += 				'</table>';
				item_input_html +=          '</div>';
		        item_input_html += 		'</div>';
				item_input_html += '</div>';
								
				item_input_html += '</div>'; // modal body

				item_input_html += '<div class="modal-footer">';
				item_input_html += 		'<div class="btn-group">';
				item_input_html += 			'<button type="button" class="btn btn-danger remove-item mdi mdi-delete">' + this.language.items.remove + '</button>';
				item_input_html += 			'<button type="button" class="btn btn-default close-item-info-dialog">' + this.language.items.close + '</button>';
				item_input_html += 		'</div>';
				item_input_html += '</div>';

				item_input_html += '</div>';
				item_input_html += '</div>';


				
				$(this.container).find('.mathflow-item-info').html(item_input_html);

				MathJax.Hub.Queue(["Typeset", MathJax.Hub, $(self.container).find(".rendered_formula")[0]]);
				
				$(this.container).find('.mathflow-item-info input.mathflow-item-input-formula').on('change', function(){
					let new_formula = $(this).val();
					let escaped_formula = new_formula.replace(/_/g, "\\_").replace(/%/g, "\\%");

					$(self.container).find(".rendered_formula").html('$$' + escaped_formula + '$$');
					MathJax.Hub.Queue(["Typeset", MathJax.Hub, $(self.container).find(".rendered_formula")[0]]);

					self.updateItem(item_id, {
						formula : new_formula
					});
				});

				
				$(this.container).find('.mathflow-item-info select.mathflow-item-select-type').on('change', function(){
					let new_input_type = $(this).val();
					let input_id = $(this).data("input-id");

					self.updateItem(item_id, {
						input : {
							id   : input_id,
							type : new_input_type
						}

					});
				});

				$(this.container).find('.mathflow-item-info .modal-header button.close, .mathflow-item-info .modal-footer button.close-item-info-dialog').on('click', function(){
					
					$(self.container).find('.mathflow-item-info').removeClass('active');
					$(self.container).find('.mathflow-item-info').html('');
					d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);
					
				});

				$(this.container).find('.mathflow-item-info .modal-footer button.remove-item').on('click', function(){
					
					$(self.container).find('.mathflow-item-info').removeClass('active');
					$(self.container).find('.mathflow-item-info').html('');
					d3.select('#' + self.svg_id).selectAll(".mathflow-item-group").classed("selected-item", false);

					self.removeItem(item_id);

				});


				$(this.container).find('.mathflow-item-info select.mathflow-item-compare-operator').on('change', function(){
					let new_operator = $(this).val();

					self.updateItem(item_id, {
						compare_operator : new_operator
					});
				});

				$(this.container).find('.mathflow-item-info input.mathflow-item-input-compare-value').on('change', function(){
					let new_value = $(this).val();

					self.updateItem(item_id, {
						compare_value : Number(new_value)
					});
				});

				$(this.container).find('.mathflow-item-info input.mathflow-item-input-name').on('change', function(){
					let new_name = $(this).val();

					self.updateItem(item_id, {
						name : new_name
					});
				});

				
		

			break;
		}


		$(this.container).trigger(this.defs.events.types.showItemInfo, {
			item : the_target_item
		});

		
	};

	$.fn.mathflow = function(opts) {
        return this.each(function() {
            var o = $(this);
            if (!o.data('mathflow')) {
                o.data('mathflow', new MathFlow(this, opts));
            }
        });
    };

    scope.MathFlowUI = MathFlow;

    return scope.MathFlowUI;

});
