/**
 * Easy Tree custom
 * @Copyright Rubén Escobar 2021
 * @Author yuez
 * @Version 0.2
 * 
 * This library is used to display content trees with different functionalities.
 * The configuration parameters and the different functions of which it is composed are detailed below.
 * 
 * --- Configuration parameters ---
 * selectable:          Enables the option to select the nodes of the tree.
 * deletable:           Enables the option to delete the nodes of the tree.
 * editable:            Enables the option to edit the nodes of the tree.
 * addable:             Enables the option to add nodes to the tree.
 * movable:             Enables the option to move nodes trough the tree.
 * collapsable:         Enables the option to the tree to be collapsable.
 * allow_duplicates:    Enables the option to add duplicated tags on the tree.
 * only_info:           Show the "only_info" view of the tree.
 * collapsed:           Show the tree collapsed at start.
 * tree_content:        Content to set the tree data.
 * i18n:                The translations for all the texts on the tree interface.
 * 
 * --- Private Functions ---
 * getSelectedItems:    To get the selectes nodes of the tree.
 * validTag:            Find the first node of the branch and call to isValidTag.
 * isValidTag:          To check that the tag entered is not repeated within the same branch of the tree.
 * getInfo:             Auxiliar function to walk through the tree getting it contents.
 * addableClick:        Auxiliar function to add the buttons and option to add nodes to the tree.
 * editableClick:       Auxiliar function to add the buttons and option to edit nodes to the tree.
 * deletableClick:      Auxiliar function to add the buttons and option to delete nodes of the tree.
 * showOnlyInfo:        Auxiliar function to change the view from the standard template to the only_info one.
 * 
 * --- Public Functions ---
 * initialize:  The function to initialize the tree. Set the options values, set the starting data and perform the view.
 * printTree:   Function to set the data of the tree.
 * getTree:     Returns the contents of the tree in an array.
 * collapseAll: Function to collapse all the parent nodes of the tree.
 * expandAll:   Function to expand all the parent nodes of the tree.
 * 
 */
 (function ($) {
    

    $.fn.easyTree = function (options) {
        
        // Define private default variables
        const defaults = {
            selectable      : true,
            deletable       : false,
            editable        : false,
            addable         : false,
            movable         : true,
            collapsable     : true,
            allow_duplicates: false,
            only_info       : false,
            collapsed       : false,
            tree_content    : [],
            i18n            : {
                deleteNull          : 'Select elements to delete',
                deleteConfirmation  : 'Confirm delete',
                confirmButtonLabel  : 'Confirm',
                editNull            : 'Select element to edit',
                editMultiple        : 'Only one element to edit',
                addMultiple         : 'Select element to add',
                collapseBranch      : 'Colapse branch',
                expandBranch        : 'Expand branch',
                selectTip           : 'Select tip',
                unselectTip         : 'Unselect',
                editTip             : 'Edit',
                addTip              : 'Add',
                deleteTip           : 'Delete',
                cancelButtonLabel   : 'Cancel',
                tag                 : 'Tag',
                name                : 'Name',
                moveLeftTip         : 'Move left',
                moveRightTip        : 'Move right',
                moveUpTip           : 'Move up',
                moveDownTip         : 'Move down',
                moveNull            : 'Select element to move',
                moveConfirmation    : 'Confirm move'
            }
        };

        options = $.extend(defaults, options);

        if(options.only_info){
            options.selectable = options.deletable = options.editable = options.addable = options.movable = false;
        }

        // Private variables
        const warningAlert = $('<div class="alert alert-warning alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><strong></strong><span class="alert-content"></span> </div> ');
        const dangerAlert = $('<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><strong></strong><span class="alert-content"></span> </div> ');

        const createInput = $('<div id="createImput"><div class="input-group tree-tag">'
                                +'<input name="tag" placeholder="' + options.i18n.tag + '" type="text" class="form-control">'
                                +'<span class="input-group-btn"><button type="button" class="btn btn-default btn-success confirm"></button></span>'
                            +'</div><div class="input-group tree-name">'
                                +'<input name="name" placeholder="' + options.i18n.name + '" type="text" class="form-control">'
                                +'<span class="input-group-btn"><button type="button" class="btn btn-default cancel"></button></span>'
                            +'</div></div>');

        const editImput = $('<div id="editImput"><div class="input-group tree-tag">'
                            +'<input name="tag" placeholder="' + options.i18n.tag + '" type="text" class="form-control">'
                            +'<span class="input-group-btn"><button type="button" class="btn btn-default btn-primary confirm"></button></span>'
                        +'</div><div class="input-group tree-name">'
                            +'<input name="name" placeholder="' + options.i18n.name + '" type="text" class="form-control">'
                            +'<span class="input-group-btn"><button type="button" class="btn btn-default cancel"></button></span>'
                        +'</div></div>');

        let easyTree = null;
        const that = this;

        // Private methos
        const getSelectedItems = function () {
            return $(easyTree).find('li.li_selected');
        };

        const validTag = function(tag, element, isEdit){

            console.log("ValidTag fired!!!");
            
            while(!element.parent().parent().hasClass('easy-tree')){
                element = element.parent().parent();
            }

            //console.log("El nodo raíz es: ", element);
            //console.log("TAG a validar: ", tag);
            //console.log("¿Es edición?", isEdit);
            //console.log("¿Se permiten duplicados?", options.allow_duplicates);

            if(options.allow_duplicates){
                return true;
            }else{
                return isValidTag(tag, element, isEdit);
            }
        };

        const isValidTag = function(tag, treeNode, isEdit){
            //console.log('Nodo a evaluar: ', treeNode);
            //console.log("Su tag: ", treeNode.find('> div > label').text().slice(1));
            //console.log("¿Tiene hijos? ", treeNode.hasClass('parent_li'));

            if(isEdit){
                if(!treeNode.hasClass('li_selected') && treeNode.find('> div > label').text().slice(1) === tag){
                    //console.log("El tag del nodo coincide con el tag a validar");
                    return false;
                }
            }else{
                if(treeNode.find('> div > label').text().slice(1) === tag){
                    //console.log("El tag del nodo coincide con el tag a validar");
                    return false;
                }
            }

            if(treeNode.hasClass('parent_li')){
                const children = treeNode.find(' > ul > li');
                
                for(const child of children){
                    if(!isValidTag(tag, $(child), isEdit)){
                        return false;
                    }
                }
            }

            return true;
        }

        const getInfo = function(current_element, out, pos){
            //console.log('Me llega: ', current_element);
            $.each(current_element, function(){
                out[pos]={};
                if($(this).is('li:has(ul)')) {
                    //console.log('Tengo hijos');
                    const children = $(this).find(' > ul > li');
                    //console.log('Son estos: ', children);
                    out[pos].name = $(this).find(' > div > div.name_block > a').text();
                    out[pos].tag = $(this).find(' > div > label').text().slice(1);
                    //console.log('El nombre del padre: ', out[pos].name);                
                    //console.log('El tag del padre: ', out[pos].tag);
                    out[pos].children = [];
                    getInfo(children, out[pos].children, 0);
                    //console.log('Sus hijos: ', out[pos].children);
                }else{
                    out[pos].name = $(this).find(' > div > div.name_block > a').text();
                    //console.log('No tengo hijos: ', out[pos].name);
                    out[pos].tag = $(this).find(' > div > label').text().slice(1);
                    //console.log('El tag: ', out[pos].tag);
                }
                pos++;
            });
        };

        const addableClick = function () {
    
            $(easyTree).find('.easy-tree-toolbar > div > button').addClass("disabled");            
            const selected = getSelectedItems();
            if(selected.length !== 0){
                $('#whiteSpace').remove();
                selected.find(' > .treeNode').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
            }else{
                $(easyTree).find(' > ul').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
            }
            let top = $("#whiteSpace").offset().top - $(easyTree).offset().top;

            $(easyTree).append(createInput);
            $(easyTree).find('#createImput').css('position', 'absolute');
            $(easyTree).find('#createImput').css('left','0px');
            $(easyTree).find('#createImput').css('top', top + 10);
            $(easyTree).find('#createImput').css('display', 'inline-block');
            $(easyTree).find('#createImput').on('keydown', function(event) {
                if (event.code == 13) {
                    event.preventDefault();
                }
            });

            $(createInput).find('input[name=name]').val('');
            $(createInput).find('input[name=tag]').val('');
            $(createInput).find('input[name=tag]').removeClass('inputError');
            $(createInput).find('input[name=tag]').trigger( "focus" );
            $(createInput).find('.confirm').text(options.i18n.confirmButtonLabel);
            $(createInput).find('.confirm').on('click', function () {
                if ($(createInput).find('input[name=name]').val() === '' || $(createInput).find('input[name=tag]').val() === ''){
                    return;
                }else if($(selected).length !== 0 && !options.allow_duplicates && !validTag($(createInput).find('input[name=tag]').val(), $(selected), false)){
                    console.log("Duplicado al añadir");
                    $(createInput).find('input[name=tag]').addClass('inputError');
                    return;
                }

                const item = $('<li>'
                                +'<div class="treeNode">'
                                    +'<div class="name_block">'
                                        +'<i data-toggle="tooltip" data-trigger="hover" title="" class="mdi mdi-18px mdi-menu-right"></i>'  //Esto es un hijo
                                        +'<a data-toggle="tooltip" data-trigger="hover" data-placement="right" title="' + options.i18n.selectTip + '">' + $(createInput).find('input[name=name]').val() + '</a>'
                                    +'</div>'
                                    +'<label>#' + $(createInput).find('input[name=tag]').val() + '</label>'
                                +'</div>'
                            +'</li>');

                $('[data-toggle="tooltip"]').tooltip('fixTitle');
                if (selected.length <= 0) {
                    $(easyTree).find(' > ul').append($(item));
                } else if (selected.length > 1) {
                    $('#whiteSpace').remove();
                    selected.find(' > .treeNode').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
                    top = $("#whiteSpace").offset().top - $(easyTree).offset().top;
                    $(easyTree).append(warningAlert);
                    $(easyTree).find('.alert-warning').css('position', 'absolute');
                    $(easyTree).find('.alert-warning').css('left','0px');
                    $(easyTree).find('.alert-warning').css('top', top + 10);
                    $(easyTree).find('.alert-warning').css('display', 'inline-block');
                    $(easyTree).find('.alert-warning').css('width', '100%');
                    $(easyTree).find('.alert .alert-content').html(options.i18n.addMultiple);
                } else {
                    if ($(selected).hasClass('parent_li')) {
                        $(selected).find(' > ul').append(item);
                    } else {
                        $(selected).addClass('parent_li').find(' > div > div.name_block > i').attr('title', options.i18n.collapseBranch).addClass('mdi-menu-down').removeClass('mdi-menu-right');  //Aquí habría que quitar la clase del hijo
                        $(selected).find(' > div > div.name_block > a').attr('href', 'javascript: void(0);');
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
                        $(selected).append($('<ul></ul>')).find(' > ul').append(item);
                    }
                }
                $(createInput).find('input[name=name]').val('');
                $(createInput).find('input[name=tag]').val('');

                if (options.selectable) {
                    $(item).find(' > div > div.name_block > a').attr('title', options.i18n.selectTip).attr('href', 'javascript: void(0);');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(item).find(' > div > div.name_block > a').on('click', function (e) {
                        $(item).find(' > div > div.name_block > a').tooltip('hide');
                        const li = $(this).parent().parent().parent();
                        if (li.hasClass('li_selected')) {
                            $(this).attr('title', options.i18n.selectTip);
                            $(li).removeClass('li_selected');
                            $(easyTree).find('.easy-tree-toolbar').appendTo(easyTree);
                            $(easyTree).find('.easy-tree-toolbar').css('display', 'none');
                            //$(easyTree).find('.easy-tree-toolbar').css('margin-left', '');
                            $(easyTree).find('.easy-tree-bottom-toolbar .create > button').removeClass('disabled');
                        }else{
                            $(easyTree).find('li.li_selected').removeClass('li_selected');
                            $(this).attr('title', options.i18n.unselectTip);
                            $(li).addClass('li_selected');
                            li.find('label').first().after($(easyTree).find('.easy-tree-toolbar'));
                            $(easyTree).find('.easy-tree-toolbar').css('display', 'inline-table');
                            //$(easyTree).find('.easy-tree-toolbar').css('margin-left', '5px');
                            $(easyTree).find('.easy-tree-bottom-toolbar .create > button').addClass('disabled');
                        }
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');

                        if (options.deletable || options.editable || options.addable) {
                            const selected = getSelectedItems();
                            if (options.editable) {
                                if (selected.length <= 0 || selected.length > 1)
                                    $(easyTree).find('.easy-tree-toolbar .edit > button').addClass('disabled');
                                else
                                    $(easyTree).find('.easy-tree-toolbar .edit > button').removeClass('disabled');
                            }

                            if (options.deletable) {
                                if (selected.length <= 0 || selected.length > 1)
                                    $(easyTree).find('.easy-tree-toolbar .remove > button').addClass('disabled');
                                else
                                    $(easyTree).find('.easy-tree-toolbar .remove > button').removeClass('disabled');
                            }

                            if (options.movable) {
                                
                                $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');

                                if(selected.length === 1){
                                    //console.log("addableClick - Su padre es easy-tree: ", $(selected).parent().parent().hasClass('easy-tree'));
                                    //console.log("addableClick - Es el primer li: ", $(selected).parent().find(' > li:first').hasClass('li_selected'));
                                    //console.log("addableClick - Es el último li: ", $(selected).parent().find(' > li:last').hasClass('li_selected'));
                                    if(!$(selected).parent().parent().hasClass('easy-tree')){
                                        $(easyTree).find('.easy-tree-toolbar .moveLeft > button').removeClass('disabled');
                                    }
                                    if(!$(selected).parent().find(' > li:first').hasClass('li_selected')){
                                        $(easyTree).find('.easy-tree-toolbar .moveUp > button').removeClass('disabled');
                                        $(easyTree).find('.easy-tree-toolbar .moveRight > button').removeClass('disabled');
                                    }
                                    if(!$(selected).parent().find(' > li:last').hasClass('li_selected')){
                                        $(easyTree).find('.easy-tree-toolbar .moveDown > button').removeClass('disabled');
                                    }
                                }
                            }

                        }
                        e.stopPropagation();
                    });
                }
                $(easyTree).trigger("easytree.change");
                $(createInput).remove();
                $('#whiteSpace').remove();                    
                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");

                if($(selected).parent().parent().hasClass('easy-tree')){
                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                }
            });
            $(createInput).find('.cancel').text(options.i18n.cancelButtonLabel);
            $(createInput).find('.cancel').on('click', function () {
                $(createInput).remove();
                $('#whiteSpace').remove();
                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                
                if($(selected).parent().parent().hasClass('easy-tree')){
                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                }
            });
        };

        const editableClick = function () {
    
            $(easyTree).find('.easy-tree-toolbar > div > button').addClass("disabled");            
            const selected = getSelectedItems();
            $('#whiteSpace').remove();
            selected.find(' > .treeNode').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
            const top = $("#whiteSpace").offset().top - $(easyTree).offset().top;

            $(easyTree).append(editImput);
            $(easyTree).find('#editImput').css('position', 'absolute');
            $(easyTree).find('#editImput').css('left','0px');
            $(easyTree).find('#editImput').css('top', top + 10);
            $(easyTree).find('#editImput').css('display', 'inline-block');
            $(easyTree).find('#editImput').keypress(function(event) {
                if (event.keyCode == 13) {
                    event.preventDefault();
                }
            });

            $(editImput).find('input[name=name]').val(selected.find(' > div > div.name_block > a').text());
            $(editImput).find('input[name=tag]').val(selected.find(' > div > label').text().slice(1));
            $(editImput).find('input[name=tag]').removeClass('inputError');
            $(editImput).find('input[name=tag]').focus();

            $(editImput).find('.confirm').text(options.i18n.confirmButtonLabel);
            $(editImput).find('.confirm').on('click', function () {

                if ($(editImput).find('input[name=name]').val() === '' || $(editImput).find('input[name=tag]').val() === ''){
                    return;
                }else if(!options.allow_duplicates && !validTag($(editImput).find('input[name=tag]').val(), $(selected), true)){
                    //console.log("Duplicado al editar");
                    $(editImput).find('input[name=tag]').addClass('inputError');
                    return;
                }
                
                selected.find(' > div > div.name_block > a').text($(editImput).find('input[name=name]').val());
                selected.find(' > div > label').text('#' + $(editImput).find('input[name=tag]').val());
                
                $(editImput).find('input[name=name]').val('');
                $(editImput).find('input[name=tag]').val('');

                $(easyTree).trigger("easytree.change");
                $(editImput).remove();
                $('#whiteSpace').remove();
                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");

                if($(selected).parent().parent().hasClass('easy-tree')){
                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                }
            });

            $(editImput).find('.cancel').text(options.i18n.cancelButtonLabel);
            $(editImput).find('.cancel').on('click', function () {
                $(editImput).remove();
                $('#whiteSpace').remove();
                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                if($(selected).parent().parent().hasClass('easy-tree')){
                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                }
                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                }
            });
        };

        const deletableClick = function () {

            $(easyTree).find('.easy-tree-toolbar > div > button').addClass("disabled");
            const selected = getSelectedItems();
            $('#whiteSpace').remove();
            selected.find(' > .treeNode').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
            const top = $("#whiteSpace").offset().top - $(easyTree).offset().top;

            if (selected.length <= 0) {
                $(easyTree).append(warningAlert);
                $(easyTree).find('.alert-warning').css('position', 'absolute');
                $(easyTree).find('.alert-warning').css('left','0px');
                $(easyTree).find('.alert-warning').css('top', top + 10);
                $(easyTree).find('.alert-warning').css('display', 'inline-block');
                $(easyTree).find('.alert-warning').css('width', '100%');
                $(easyTree).find('.alert .alert-content').html(options.i18n.deleteNull);
            } else {
                $(easyTree).append(dangerAlert);
                $(easyTree).find('.alert-danger').css('position', 'absolute');
                $(easyTree).find('.alert-danger').css('left','0px');
                $(easyTree).find('.alert-danger').css('top', top + 10);
                $(easyTree).find('.alert-danger').css('display', 'inline-block');
                $(easyTree).find('.alert-danger').css('width', '100%');
                $(easyTree).find('.alert .alert-content').html(options.i18n.deleteConfirmation)
                    .append('<a style="margin-left: 10px;" class="btn btn-default btn-sm btn-danger confirm"></a>')
                    .find('.confirm').html(options.i18n.confirmButtonLabel);
                    
                $(easyTree).find('.alert .alert-content .confirm').on('click', function () {

                    $('#whiteSpace').remove();
                    $(easyTree).find('.easy-tree-toolbar').css('display', 'none');
                    $(easyTree).find('.easy-tree-toolbar').css('margin-left', '');
                    $(easyTree).append($(easyTree).find('.easy-tree-toolbar'));
                    $(easyTree).find('.easy-tree-bottom-toolbar .create > button').removeClass('disabled');

                    // Delete children if any
                    $(selected).find(' ul ').remove();

                    // Check for more sibling nodes
                    if($(selected).parent('ul').find(' > li').length <= 1) {                                                                     //Aquí habría que añadir la clase del hijo
                        $(selected).parent('ul').parent('li').removeClass('parent_li').find(' > div > div.name_block > i').removeClass('mdi-menu-down').addClass('mdi-menu-right').attr('title', "");
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
                        if(!$(selected).parent().parent().hasClass('easy-tree')){
                            $(selected).parent('ul').remove();
                        }
                    }
                    $(selected).remove();
                    $(dangerAlert).remove();                            
                    $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                    $(easyTree).trigger("easytree.change");
                });

                $(easyTree).find('.alert .close').on('click', function () {
                    $('#whiteSpace').remove();
                    $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                    if($(selected).parent().parent().hasClass('easy-tree')){
                        $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                    }
                    if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                        $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                        $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                    }
                    if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                        $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                    }
                });
            }
        };

        const movableClick = function (nodeClass) {

            $(easyTree).find('.easy-tree-toolbar > div > button').addClass("disabled");
            const selected = getSelectedItems();
            //console.log("El seleccionado: ", selected);

            if (selected.length <= 0) {
                $('#whiteSpace').remove();
                selected.find(' > .treeNode').after('<div id="whiteSpace" style="padding-top:65px;display:flex;"></div>');
                const top = $("#whiteSpace").offset().top - $(easyTree).offset().top;
                $(easyTree).append(warningAlert);
                $(easyTree).find('.alert-warning').css('position', 'absolute');
                $(easyTree).find('.alert-warning').css('left','0px');
                $(easyTree).find('.alert-warning').css('top', top + 10);
                $(easyTree).find('.alert-warning').css('display', 'inline-block');
                $(easyTree).find('.alert-warning').css('width', '100%');
                $(easyTree).find('.alert .alert-content').html(options.i18n.moveNull);
            } else {
                
                let pos = null;
                switch(nodeClass){
                    case 'left':
                        let parent_to_remove = null;
                        if(!$(selected).parent().hasClass('easy-tree') && $(selected).parent('ul').find(' > li').length <= 1) {                     //Aquí habría que añadir la clase del hijo
                            $(selected).parent('ul').parent('li').removeClass('parent_li').find(' > div > div.name_block > i').removeClass('mdi-menu-down').addClass('mdi-menu-right').attr('title', "");
                            $('[data-toggle="tooltip"]').tooltip('fixTitle');
                            if(!$(selected).parent().parent().hasClass('easy-tree')){
                                parent_to_remove = $(selected).parent('ul');
                            }
                        }
                        $(selected).parent().parent().after($(selected));

                        $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                        
                        // Check element position to enable or disable move buttons
                        if($(selected).parent().parent().hasClass('easy-tree')){
                            $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                        }
                        if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                            $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                        }

                        if(parent_to_remove !== null){
                            parent_to_remove.remove();
                        }

                        $(easyTree).find('.alert .close').on('click', function () {
                            $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");
                            if($(selected).parent().parent().hasClass('easy-tree')){
                                $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                            }
                            if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                                $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                            }
                            if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                                $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                            }
                        });
                        break;

                    case 'up':
                        pos = null;
                        $(selected).parent('ul').find(' > li').each(function(){
                            if($(this).hasClass('li_selected')){
                                $(pos).before($(selected));
                                
                                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");

                                // Check element position to enable or disable move buttons
                                if($(selected).parent().parent().hasClass('easy-tree')){
                                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                                }
                                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                                }
                            }else{
                                pos = this;
                            }
                        });
                        break;

                    case 'down':
                        pos = false;
                        $(selected).parent('ul').find(' > li').each(function(){
                            if(pos){
                                $($(this)).after($(selected));

                                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");

                                // Check element position to enable or disable move buttons
                                if($(selected).parent().parent().hasClass('easy-tree')){
                                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                                }
                                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                                }
                                return false;
                            }
                            if($(this).hasClass('li_selected')){
                                pos = true;
                            }
                        });
                        break;

                    case 'right':
                        pos = null;
                        $(selected).parent('ul').find(' > li').each(function(){
                            if($(this).hasClass('li_selected')){
                                
                                if($(pos).hasClass('parent_li')){
                                    $(pos).find(' > ul').append($(selected));
                                }else{
                                    $(pos).addClass('parent_li').find(' > div > div.name_block > i').attr('title', options.i18n.collapseBranch).addClass('mdi-menu-down').removeClass('mdi-menu-right');  //Aquí habría que quitar la clase del hijo
                                    $(pos).find(' > div > div.name_block > a').attr('href', 'javascript: void(0);');
                                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                                    $(pos).append($('<ul></ul>')).find(' > ul').append($(selected));
                                }                                
                                
                                $(easyTree).find('.easy-tree-toolbar > div > button').removeClass("disabled");

                                // Check element position to enable or disable move buttons
                                if($(selected).parent().parent().hasClass('easy-tree')){
                                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                                }
                                if($(selected).parent().find(' > li:first').hasClass('li_selected')){
                                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');
                                }
                                if($(selected).parent().find(' > li:last').hasClass('li_selected')){
                                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                                }
                            }else{
                                pos = this;
                            }
                        });
                        break;
                    }
                $(easyTree).trigger("easytree.change");
            }
        };

        const showOnlyInfo = function(){

            $(easyTree).find('li > div > label').each(function(){
                $(this).css('display', 'none');
            });

            $(easyTree).find('li').each(function(){
                $(this).addClass('only_info');
            });

            $(easyTree).find(' > ul > li.only_info > div > div.name_block > i').remove();
            $(easyTree).find(' > ul > li.only_info > div > div.name_block').append('<i data-toggle="tooltip" data-trigger="hover" title="" class="mdi mdi-18px mdi-dots-horizontal dots-first-level hidden"></i>');
            $(easyTree).find(' > ul > li.only_info > div > div.name_block > i').attr('title', options.i18n.collapseBranch);
        }

        // Public methos
        this.initialize = function() {

            this.each(function () {
                easyTree = $(this);
                console.log("easyTree on initialize: ", easyTree);

                if(options.tree_content && options.tree_content.length > 0){
                    //console.log("Tree Content: ", options.tree_content);
                    //console.log("Tree Content: ", JSON.parse(options.tree_content));
                    let tree_content_parsed = JSON.parse(options.tree_content);
                    for( let i=0; i<tree_content_parsed.length; i++){
                        //console.log("printTree con");
                        //console.log("Array element: ", tree_content_parsed[i]);
                        //console.log("Html element: ", $(easyTree).find('> ul'));
                        that.printTree($(easyTree).find('> ul'), tree_content_parsed[i], i);
                    }
                }

                console.log("Transform normal html to easyTree content");

                $.each($(easyTree).find('ul > li'), function() {
                    //console.log("Elemento: ", $(this));
                    let name, tag, elements_html;

                    if($(this).is('li:has(ul)') || $(this).is('li:has(ol)')) {
                        const children = $(this).find(' > ul');
                        $(children).remove();
                        name = $(this).find('> span').text();
                        tag = $(this).find('> label').text();
                        elements_html = $(this).find(' > div.elements').html();
                        $(this).html('<div class="treeNode"><div class="name_block"><i data-toggle="tooltip" data-trigger="hover" class="mdi mdi-18px" title=""></i><a href="javascript: void(0);" data-toggle="tooltip" data-trigger="hover"></a></div><label></label></div>');
                        $(this).find(' > div > div.name_block > i').addClass('mdi-menu-down');
                        $(this).find(' > div > div.name_block > a').text(name);
                        $(this).find(' > div > label').text('#'+tag);
                        $(this).append(elements_html);
                        $(this).append(children);
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    }else{
                        name = $(this).find('> span').text();
                        tag = $(this).find('> label').text();
                        elements_html = $(this).find(' > div.elements').html();
                        $(this).html('<div class="treeNode"><div class="name_block"><i data-toggle="tooltip" data-trigger="hover" class="mdi mdi-18px" title=""></i><a data-toggle="tooltip" data-trigger="hover"></a></div><label></label></div>');
                        $(this).find(' > div > div.name_block > i').addClass('mdi-menu-right');    //Aquí habría que añadir la clase del hijo
                        $(this).find(' > div > div.name_block > a').text(name);
                        $(this).find(' > div > label').text('#'+tag);    
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
                        $(this).append(elements_html);
                    }
                });
    
                $(easyTree).find('li:has(ul)').addClass('parent_li').find(' > div > div.name_block > i').attr('title', options.i18n.collapseBranch);
                $(easyTree).find('li:has(ol)').addClass('parent_li').find(' > div > div.name_block > i').attr('title', options.i18n.collapseBranch);
                $('[data-toggle="tooltip"]').tooltip('fixTitle');
    
                // add easy tree toolbar dom
                if (options.deletable || options.editable || options.addable) {
                    $(easyTree).prepend('<div class="easy-tree-toolbar"></div> ');
                    $(easyTree).find('.easy-tree-toolbar').css('display', 'none');
                }
                
                // addable
                if (options.addable) {
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="create"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.addTip + '" class="btn btn-default btn-sm btn-success"><span class="mdi mdi-plus"></span></button></div> ');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(easyTree).find('.easy-tree-toolbar .create > button').on('click', addableClick);
                    
                    $(easyTree).append('<div class="easy-tree-bottom-toolbar"></div> ');
                    $(easyTree).find('.easy-tree-bottom-toolbar').append('<div class="create"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.addTip + '" class="btn btn-default btn-sm btn-success"><span class="mdi mdi-plus"></span></button></div> ');
                    $(easyTree).find('.easy-tree-bottom-toolbar .create > button').on('click', addableClick);
                }
                
                // editable
                if (options.editable) {
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="edit"><button data-toggle="tooltip" data-trigger="hover" data-trigger="hover" data-placement="top" title="' + options.i18n.editTip + '" class="btn btn-default btn-sm btn-primary disabled"><span class="mdi mdi-pencil"></span></button></div> ');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(easyTree).find('.easy-tree-toolbar .edit > button').on('click', editableClick);
                }

                //movable
                if(options.movable){
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="moveLeft"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.moveLeftTip + '" class="btn btn-default btn-sm btn-primary disabled"><span class="mdi mdi-chevron-double-left"></span></button></div> ');
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="moveUp"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.moveUpTip + '" class="btn btn-default btn-sm btn-primary disabled"><span class="mdi mdi-chevron-double-up"></span></button></div> ');
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="moveDown"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.moveDownTip + '" class="btn btn-default btn-sm btn-primary disabled"><span class="mdi mdi-chevron-double-down"></span></button></div> ');
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="moveRight"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.moveRightTip + '" class="btn btn-default btn-sm btn-primary disabled"><span class="mdi mdi-chevron-double-right"></span></button></div> ');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(easyTree).find('.easy-tree-toolbar .moveLeft > button').on('click', function(){movableClick('left')});
                    $(easyTree).find('.easy-tree-toolbar .moveUp > button').on('click', function(){movableClick('up')});
                    $(easyTree).find('.easy-tree-toolbar .moveDown > button').on('click', function(){movableClick('down')});
                    $(easyTree).find('.easy-tree-toolbar .moveRight > button').on('click', function(){movableClick('right')});
                }

                // deletable
                if (options.deletable) {
                    $(easyTree).find('.easy-tree-toolbar').append('<div class="remove"><button data-toggle="tooltip" data-trigger="hover" data-placement="top" title="' + options.i18n.deleteTip + '" class="btn btn-default btn-sm btn-danger disabled"><span class="mdi mdi-delete"></span></button></div> ');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(easyTree).find('.easy-tree-toolbar .remove > button').on('click', deletableClick);
                }
    
                // collapse or expand
                if(options.collapsable){
                    $(easyTree).find('li.parent_li > div > div.name_block').on('click', function (e) {
                        $(this).find('> i').tooltip('hide');
                        const children_nodes = $(this).parent('div').parent('li.parent_li').find(' > ul > li');
                        const children_links = $(this).parent('div').parent('li.parent_li').find(' > ol > li');

                        //console.log("Los children_nodes sobre los que voy a actuar: ", children_nodes);
                        //console.log("Los children_links sobre los que voy a actuar: ", children_links);

                        if(children_nodes.is(':visible') || children_links.is(':visible')){
                            children_nodes.hide('fast');
                            children_links.hide('fast');
                            if(options.only_info && $(this).find(' > i').hasClass('dots-first-level')){
                                $(this).find('> i').attr('title', options.i18n.expandBranch).removeClass('hidden');
                            }else{
                                $(this).find('> i').attr('title', options.i18n.expandBranch)
                                    .addClass('mdi-menu-right')
                                    .removeClass('mdi-menu-down');
                            }
                        }else{
                            children_nodes.show('fast');
                            children_links.show('fast');
                            if(options.only_info && $(this).find(' > i').hasClass('dots-first-level')){
                                $(this).find('> i').attr('title', options.i18n.collapseBranch).addClass('hidden');
                            }else{
                                $(this).find('> i').attr('title', options.i18n.collapseBranch)
                                    .addClass('mdi-menu-down')
                                    .removeClass('mdi-menu-right');
                            }
                        }

                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
                        e.stopPropagation();
                    });
                }
    
                // selectable, only single select
                if (options.selectable) {    
                    $(easyTree).find('li > div > div.name_block > a').attr('title', options.i18n.selectTip).attr('href', 'javascript: void(0);');
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                    $(easyTree).find('li > div > div.name_block > a').on('click', function (e) {
                        const li = $(this).parent().parent().parent();
    
                        //console.log("¿Está seleccionado? ", li.hasClass('li_selected'));
    
                        if (li.hasClass('li_selected')) {
                            $(this).attr('title', options.i18n.selectTip);
                            $(li).removeClass('li_selected');
                            $(easyTree).find('.easy-tree-toolbar').appendTo(easyTree);
                            $(easyTree).find('.easy-tree-toolbar').css('display', 'none');
                            //$(easyTree).find('.easy-tree-toolbar').css('margin-left', '');
                            $(easyTree).find('.easy-tree-bottom-toolbar .create > button').removeClass('disabled');
                        }else{
                            $(easyTree).find('li.li_selected').removeClass('li_selected');
                            $(this).attr('title', options.i18n.unselectTip);
                            $(li).addClass('li_selected');
                            $(easyTree).find('.easy-tree-toolbar').appendTo(li);
                            li.find('label').first().after($(easyTree).find('.easy-tree-toolbar'));
                            $(easyTree).find('.easy-tree-toolbar').css('display', 'inline-table');
                            //$(easyTree).find('.easy-tree-toolbar').css('margin-left', '5px');
                            $(easyTree).find('.easy-tree-bottom-toolbar .create > button').addClass('disabled');
                        }
                        $('[data-toggle="tooltip"]').tooltip('fixTitle');
    
                        if (options.deletable || options.editable || options.addable) {
                            const selected = getSelectedItems();
                            if (options.editable) {
                                if (selected.length <= 0 || selected.length > 1)
                                    $(easyTree).find('.easy-tree-toolbar .edit > button').addClass('disabled');
                                else
                                    $(easyTree).find('.easy-tree-toolbar .edit > button').removeClass('disabled');
                            }
    
                            if (options.deletable) {
                                if (selected.length <= 0 || selected.length > 1)
                                    $(easyTree).find('.easy-tree-toolbar .remove > button').addClass('disabled');
                                else
                                    $(easyTree).find('.easy-tree-toolbar .remove > button').removeClass('disabled');
                            }

                            if (options.movable) {
                                
                                $(easyTree).find('.easy-tree-toolbar .moveLeft > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveUp > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveDown > button').addClass('disabled');
                                $(easyTree).find('.easy-tree-toolbar .moveRight > button').addClass('disabled');

                                if(selected.length === 1){
                                    if(!$(selected).parent().parent().hasClass('easy-tree')){
                                        $(easyTree).find('.easy-tree-toolbar .moveLeft > button').removeClass('disabled');
                                    }
                                    if(!$(selected).parent().find(' > li:first').hasClass('li_selected')){
                                        $(easyTree).find('.easy-tree-toolbar .moveUp > button').removeClass('disabled');
                                        $(easyTree).find('.easy-tree-toolbar .moveRight > button').removeClass('disabled');
                                    }
                                    if(!$(selected).parent().find(' > li:last').hasClass('li_selected')){
                                        $(easyTree).find('.easy-tree-toolbar .moveDown > button').removeClass('disabled');
                                    }
                                }
                            }
                        }
                        e.stopPropagation();
                    });
                }

                //Info Tree
                if(options.only_info){
                    showOnlyInfo();
                }

                // Collapsed
                if(options.collapsed){
                    //console.log("Este es el that:", that);
                    that.collapseAll();
                }

            });
            return this;
        };

        this.printTree = function(element_html, element_data, position){

            //console.log("Print Tree fired!");
            //console.log('printTree HTML: ', element_html);
            //console.log('printTree DATA: ', element_data);
            //console.log('printTree POSITION: ', position);
            //console.log("¿Tiene hijos?", typeof element_data.children);

            if(typeof element_data.children !== 'undefined'){
                //console.log("Tiene hijos: ", element_data.children);

                if(typeof element_data.elements !== 'undefined'){
                    let aux_html = '<li><span>' + element_data.name + '</span><label>' + element_data.tag + '</label><ul></ul><div class="elements"><ol>';
                        for(let j=0; j<element_data.elements.length; j++){
                            aux_html += element_data.elements[j].html;
                        }
                    aux_html += '</ol></div></li>';

                    //console.log("Lo que voy a meter al campo!!! ", aux_html);

                    element_html.append(aux_html);
                }else{
                    element_html.append('<li><span>' + element_data.name + '</span><label>' + element_data.tag + '</label><ul></ul></li>');
                }
                for(let i=0; i<element_data.children.length; i++){
                    //console.log("Voy a llamar al hijo ", element_data.children[i]);
                    //console.log("Con el elemento LI: ", element_html.find('>li:eq(' + position + ')'));
                    //console.log("Con el elemento UL: ", element_html.find('>li:eq(' + position + ') > ul'));
                    //console.log("Con el elemento UL: ", element_html.find('>li > ul'));

                    this.printTree(element_html.find('>li:eq(' + position + ') > ul'), element_data.children[i], i);
                }
            }else{
                //console.log("No tiene hijos: ", element_data.name);
                if(typeof element_data.elements !== 'undefined'){
                    let aux_html = '<li><span>' + element_data.name + '</span><label>' + element_data.tag + '</label><div class="elements"><ol>';
                        for(let j=0; j<element_data.elements.length; j++){
                            aux_html += element_data.elements[j].html;
                        }
                    aux_html += '</ol></div></li>';

                    //console.log("Lo que voy a meter al campo!!! ", aux_html);

                    element_html.append(aux_html);
                }else{
                    element_html.append('<li><span>' + element_data.name + '</span><label>' + element_data.tag + '</label></li>');
                }
            }
        };
        
        this.getTree = function() {

            console.log("Get Tree fired");
            let out = [];
            let i = 0;
            //console.log("UI ELEMENT: ", this.ui_element);
            //$.each($(this.ui_element).parent().find(' .easy-tree > ul > li'), function() {
            $.each($(easyTree).find(' > ul > li'), function() {
                //console.log('Rama principal: ', i);
                //console.log('Contenido: ', $(this));
                getInfo($(this), out, i);
                i++;
            });

            return out;
        },
        
        this.collapseAll = function(){

            console.log("Collapse All fired", options.collapsed);
            if(options.collapsed){
                $(this).find('li.parent_li > div > div.name_block').each(function(){
                    $(this).find('> i').tooltip('hide');
                    const children_nodes = $(this).parent('div').parent('li.parent_li').find(' > ul > li');
                    const children_links = $(this).parent('div').parent('li.parent_li').find(' > ol > li');
                    
                    children_nodes.hide('fast');
                    children_links.hide('fast');

                    if(options.only_info && $(this).find(' > i').hasClass('dots-first-level')){
                        $(this).find('> i').attr('title', options.i18n.expandBranch).removeClass('hidden');
                    }else{
                        $(this).find('> i').attr('title', options.i18n.expandBranch)
                            .addClass('mdi-menu-right')
                            .removeClass('mdi-menu-down');
                    }
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                });
            }
            return true;
        };

        this.expandAll = function(){  

            console.log("Expand All fired", options.collapsed);
            if(options.collapsed){
                $(this).find('li.parent_li > div > div.name_block').each(function(){
                    $(this).find('> i').tooltip('hide');
                    const children_nodes = $(this).parent('div').parent('li.parent_li').find(' > ul > li');
                    const children_links = $(this).parent('div').parent('li.parent_li').find(' > ol > li');
                    
                    children_nodes.show('fast');
                    children_links.show('fast');

                    if(options.only_info && $(this).find(' > i').hasClass('dots-first-level')){
                        $(this).find('> i').attr('title', options.i18n.expandBranch).addClass('hidden');
                    }else{
                        $(this).find('> i').attr('title', options.i18n.collapseBranch)
                            .addClass('mdi-menu-down')
                            .removeClass('mdi-menu-right');
                    }
                    $('[data-toggle="tooltip"]').tooltip('fixTitle');
                });
            }
            return true;
        };

        // Return the object initialized
        return this.initialize();
    };

})(jQuery);
